import { bindJWT, deleteApi, getApi, postApi } from 'lib/api/api'
import { snakeCaseKeys } from 'lib/object/utils'
import { Annotation } from '../../annotations/annotations'

interface AdminTicketFilePermissions {
  read: boolean
  destroy: boolean
}

export enum AdminTicketFileState {
  Default = 'default',
  Approved = 'approved',
  Rejected = 'rejected',
}

export interface AdminTicketFile {
  dpi?: number
  width?: number
  height?: number
  fileSize?: number
  colorspace?: string
  annotations: Annotation[]
  createdAt: string
  externalLink: string
  extractedPages: AdminTicketFile[]
  handle: string
  id: number
  imageableId: number
  imageableType: string
  isExtractable: boolean
  isVideo: boolean
  meta: {
    permissions: AdminTicketFilePermissions
  }
  mimetype: string
  name: string
  preview: boolean
  previewUrl: string
  s3PreviewUrl?: string
  state: AdminTicketFileState
  stockAsset: AdminStockAsset
  ticketId: number
  ticketVersion: number
  uploadedByCreative: boolean
  videoAnnotation: unknown // @deprecated with merged annotations work
}

export interface AdminStockAsset {
  id: number
  photoContentType: string
  photoFileName: string
  previewUrl: string
  provider: string
  viewUrl: string
}

const baseUrl = '/api/internal/admin/tickets'

export async function getTicketFilesForTicket(ticketId: number, version: number): Promise<AdminTicketFile[]> {
  const getWithJWT = bindJWT(getApi, null)
  const url = `${baseUrl}/${ticketId}/ticket_files`
  const params = version > 0 ? { version } : {}

  const response = await getWithJWT(url, params)
  return response.data.data
}

export interface AddTicketFileParams {
  externalLink?: string
  filename?: string
  handle?: string
  mimetype?: string
}

export async function addTicketFile(
  ticketId: number,
  params: AddTicketFileParams,
  jwt: string = null,
): Promise<AdminTicketFile> {
  const postWithJWT = bindJWT(postApi, jwt)
  const snakeCaseParams = snakeCaseKeys(params)
  const response = await postWithJWT(`${baseUrl}/${ticketId}/ticket_files`, snakeCaseParams)
  return response.data.data
}

export async function deleteTicketFile(ticketId: number, fileId: number, jwt: string = null): Promise<void> {
  const deleteWithJWT = bindJWT(deleteApi, jwt)

  const response = await deleteWithJWT(`${baseUrl}/${ticketId}/ticket_files/${fileId}`)
  return response.data.data
}

export async function getTicketFile(
  ticketId: number,
  ticketFileId: number,
  jwt: string = null,
): Promise<AdminTicketFile> {
  const getWithJWT = bindJWT(getApi, jwt)

  const response = await getWithJWT(`${baseUrl}/${ticketId}/ticket_files/${ticketFileId}`)
  return response.data.data
}
