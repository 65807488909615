import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import { TicketFile } from 'lib/api/ticket-files/ticket-files'
import { AdminTicketFile } from 'lib/api/admin/ticket-files/admin-ticket-files'

// Returns the S3 preview URL if the feature flag is enabled + is avialble, otherwise returns default preview URL
export function useS3PreviewImage(
  ticketFile: Pick<TicketFile, 's3PreviewUrl' | 'previewUrl'> | Pick<AdminTicketFile, 's3PreviewUrl' | 'previewUrl'>,
) {
  const featureFlagContext = useFeatureFlagsContext()
  const isFeatureFlagEnabled = featureFlagContext?.isFeatureFlagEnabled

  if (!isFeatureFlagEnabled) {
    return ticketFile?.previewUrl
  }

  return isFeatureFlagEnabled('show_s3_links') && ticketFile?.s3PreviewUrl
    ? ticketFile?.s3PreviewUrl
    : ticketFile?.previewUrl
}
