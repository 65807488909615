import { useState } from 'react'
import { ChevronLeftIcon, FolderOpenIcon, SlashIcon, TicketIcon } from 'lucide-react'
import ProjectsDrawer from 'components/elements/projects/project-drawer'
import { DesignRequest } from 'lib/api/tickets/tickets'
import { cn } from 'lib/util/cn'
import { LinkLikeButton } from 'components/core/button'

interface RequestRevisionBreadcrumbProps {
  className?: string
  ticket: DesignRequest
  onProjectUpdate?: () => void
}

export default function RequestRevisionBreadcrumb({
  className,
  ticket,
  onProjectUpdate,
}: RequestRevisionBreadcrumbProps) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={cn(className, 'tw-flex tw-items-center tw-gap-2')}>
      <a href="/requests" className="tw-text-neutral-400 hover:tw-text-cornflower-500">
        <ChevronLeftIcon className="tw-mr-3 tw-h-4 tw-w-4" />
        Back to all requests
      </a>
      {ticket?.project && (
        <>
          <SlashIcon className="tw-h-4 tw-w-4" />
          <LinkLikeButton
            onClick={() => setIsOpen(true)}
            className="tw-flex tw-items-center tw-gap-2 tw-font-normal tw-text-neutral-400 hover:tw-text-cornflower-500"
          >
            <FolderOpenIcon className="tw-h-4 tw-w-4" />
            <span className="tw-max-w-64 tw-truncate">{ticket?.project?.name}</span>
          </LinkLikeButton>
          <ProjectsDrawer isOpen={isOpen} setOpen={setIsOpen} project={ticket?.project} onUpdate={onProjectUpdate} />
        </>
      )}
      <SlashIcon className="tw-h-4 tw-w-4" />
      <div className="tw-flex tw-items-center tw-gap-2 tw-font-normal tw-text-neutral-400">
        <TicketIcon className="tw-h-4 tw-w-4" />
        <span className="tw-max-w-64 tw-truncate">{ticket?.subject || `Untitled ${ticket?.skill?.name}`}</span>
      </div>
    </div>
  )
}
