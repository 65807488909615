import { GripVertical } from 'lucide-react'
import { PanelGroup, Panel, PanelResizeHandle } from 'react-resizable-panels'
import { cn } from 'lib/util/cn'

const ResizablePanelGroup = ({ className, ...props }: React.ComponentProps<typeof PanelGroup>) => (
  <PanelGroup
    className={cn('tw-flex tw-h-full tw-w-full data-[panel-group-direction=vertical]:tw-flex-col', className)}
    {...props}
  />
)

const ResizablePanel = Panel

const ResizableHandle = ({
  withHandle,
  className,
  children,
  ...props
}: React.ComponentProps<typeof PanelResizeHandle> & {
  withHandle?: boolean
}) => (
  <PanelResizeHandle
    className={cn(
      'focus-visible:tw-ring-ring tw-relative tw-flex tw-w-px tw-items-center tw-justify-center tw-bg-neutral-200 after:tw-absolute after:tw-inset-y-0 after:tw-left-1/2 after:tw-w-1 after:-tw-translate-x-1/2 focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-offset-1 data-[panel-group-direction=vertical]:tw-h-px data-[panel-group-direction=vertical]:tw-w-full data-[panel-group-direction=vertical]:after:tw-left-0 data-[panel-group-direction=vertical]:after:tw-h-1 data-[panel-group-direction=vertical]:after:tw-w-full data-[panel-group-direction=vertical]:after:-tw-translate-y-1/2 data-[panel-group-direction=vertical]:after:tw-translate-x-0 [&[data-panel-group-direction=vertical]>div]:tw-rotate-90',
      className,
    )}
    {...props}
  >
    {withHandle && (
      <div className="tw-bg-border tw-z-10 tw-flex tw-h-4 tw-w-4 tw-items-center tw-justify-center tw-rounded-sm tw-border">
        <GripVertical className="tw-h-4 tw-w-4" />
      </div>
    )}
    {children}
  </PanelResizeHandle>
)

export { ResizablePanelGroup, ResizablePanel, ResizableHandle }
