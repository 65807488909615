import Button from 'components/core/button'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const classNames = {
  arrowIcon: 'tw-mr-3 tw-text-peppercorn-900',
  header: 'tw-flex tw-items-center navbar-custom',
  inputsContainer: 'tw-container tw-mt-14',
  input:
    'tw-w-32 tw-py-2 tw-px-8 tw-appearance-none tw-outline-none tw-shadow-none tw-border tw-border-peppercorn-100 tw-border-solid tw-rounded',
  inputWrapper: 'tw-mb-4',
  submitButton: 'tw-w-32',
  wrapper: 'tw-flex tw-w-full tw-flex-col tw-pb-4',
}

export default function AutoAssignerTimeZoneForm({ params, setParams, handleSubmit }) {
  return (
    <>
      <div className={classNames.inputsContainer}>
        <div className={classNames.inputWrapper}>
          <h4>Name</h4>
          <input
            type="text"
            value={params.name}
            onChange={(e) => setParams({ ...params, name: e.target.value })}
            className={classNames.input}
          />
          <h4>Start Time</h4>
          <DatePicker
            className={classNames.input}
            selected={params.startTime && new Date(params.startTime)}
            onChange={(date) => setParams({ ...params, startTime: date.toISOString() })}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="Time"
            dateFormat="HH:mm"
            timeFormat="HH:mm"
          />
        </div>
        <div className={classNames.inputWrapper}>
          <h4>End Time</h4>
          <DatePicker
            className={classNames.input}
            selected={params.endTime && new Date(params.endTime)}
            onChange={(date) => setParams({ ...params, endTime: date.toISOString() })}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            timeCaption="Time"
            dateFormat="HH:mm"
            timeFormat="HH:mm"
          />
        </div>
      </div>
      <div>
        <Button color="green" onClick={handleSubmit} className={classNames.submitButton}>
          Submit
        </Button>
      </div>
    </>
  )
}
