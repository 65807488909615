import { ColumnDef, flexRender, getCoreRowModel, RowSelectionState, Table, useReactTable } from '@tanstack/react-table'
import Button, { LinkLikeButton } from 'components/core/button'
import { Ticket } from 'interfaces/ticket'
import { TicketStatusBadge } from 'lib/components/badge/badge'
import Checkbox from 'lib/components/checkbox/checkbox'
import { FolderXIcon, PlusIcon, TicketIcon } from 'lucide-react'
import { useEffect, useState, useMemo } from 'react'
import { SubscriptionTypeIcon } from '../deliverable-type'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import { SubscriptionType } from 'interfaces/project-templates'
import { useInfiniteQuery } from '@tanstack/react-query'
import { request } from 'lib/api/fetch-api'
import { Skeleton } from 'lib/components/skeleton/skeleton'
import { useInView } from 'react-intersection-observer'
import { toast } from 'lib/components/toast/toast'
import DeliverablesTableTitleCell from './deliverables-table-title-cell'

interface ProjectDeliverablesTableProps {
  projectId: number
  handleAddDeliverablesClick: () => void
  handleRemoveDeliverablesClick: (deliverablesToRemove: Ticket[]) => void
}

const ProjectDeliverablesTable = ({
  projectId,
  handleAddDeliverablesClick,
  handleRemoveDeliverablesClick,
}: ProjectDeliverablesTableProps) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const { ref, inView } = useInView()

  const columns: ColumnDef<Ticket>[] = [
    {
      id: 'select-col',
      cell: ({ row, table }) => (
        <Checkbox
          className="tw-m-4"
          isChecked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onClick={(e) => {
            row.getToggleSelectedHandler()(e)
            table.setGlobalFilter(row.original.subject)
          }}
        />
      ),
      size: 8,
    },
    {
      id: 'title',
      header: 'Title',
      cell: (props) => <DeliverablesTableTitleCell ticket={props.row.original} />,
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'friendlyStatusName',
      cell: (props) => <TicketStatusBadge status={props.cell.getValue() as string} />,
    },
    {
      id: 'priority',
      header: 'Queue Priority',
      accessorKey: 'position',
      cell: (props) => <span>{props.cell.getValue() ? (props.cell.getValue() as number) : '-'}</span>,
    },
    {
      id: 'type',
      header: 'Deliverable Type',
      accessorKey: 'category',
      cell: (props) => <SubscriptionTypeIcon type={props.cell.getValue() as SubscriptionType} />,
    },
    {
      id: 'actions',
      enableSorting: false,
      cell: () => (
        <IconFlyoutMenu icon={['far', 'ellipsis-v']} color="transparent">
          TODO
        </IconFlyoutMenu>
      ),
      size: 8,
    },
  ]

  const { data, isLoading, error, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ['projectDeliverables', projectId],
    queryFn: ({ pageParam = 1 }) =>
      request({ endpoint: 'getProjectDeliverables', query: { id: projectId, page: pageParam } }),
    getNextPageParam: (lastPage, pages) => (lastPage.meta?.nextPage ? pages.length + 1 : undefined),
  })

  if (error) {
    toast.error('Failed to fetch project deliverables')
    console.error('Failed to fetch project deliverables', error)
  }

  const table = useReactTable({
    columns,
    data: useMemo(() => data?.pages.flatMap((page) => page.data), [data?.pages]) ?? [],
    state: {
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
  })

  useEffect(() => {
    if (inView && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, inView, isFetchingNextPage])

  return (
    <div>
      <div className="tw-mb-4 tw-flex tw-items-center tw-justify-between">
        <h5>
          <TicketIcon className="lu-md" /> Deliverables ({data?.pages[0].meta.total ?? 0})
        </h5>
        <LinkLikeButton onClick={handleAddDeliverablesClick} className="tw-justify-center">
          <PlusIcon className="lu-md" /> Add deliverable(s)
        </LinkLikeButton>
      </div>
      <div>
        <table className="tw-w-full">
          <thead>
            <tr className="tw-h-12 tw-w-full tw-bg-peppercorn-50">
              <TableHeaders
                table={table}
                rowSelection={rowSelection}
                data={data?.pages}
                handleRemoveDeliverablesClick={handleRemoveDeliverablesClick}
              />
            </tr>
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="tw-h-24 tw-border-s-2 tw-border-solid tw-border-peppercorn-100 tw-p-2">
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} style={{ width: `${cell.column.getSize()}px` }}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
            <tr ref={ref} className="tw-h-4" />
          </tbody>
        </table>
        {(isLoading || isFetchingNextPage) && (
          <Skeleton className="tw-h-24 tw-w-full tw-bg-neutral-200" data-testid="skeleton" />
        )}
      </div>
    </div>
  )
}

interface TableHeadersProps {
  table: Table<Ticket>
  rowSelection: Record<string, boolean>
  data?: { data: Ticket[] }[]
  handleRemoveDeliverablesClick: (deliverablesToRemove: Ticket[]) => void
}

interface BulkActionHeadersProps {
  table: Table<Ticket>
  rowSelection: Record<string, boolean>
  data?: { data: Ticket[] }[]
  handleRemoveDeliverablesClick: (deliverablesToRemove: Ticket[]) => void
}

const BulkActionHeaders = ({ table, rowSelection, data, handleRemoveDeliverablesClick }: BulkActionHeadersProps) => {
  return (
    <th scope="col" colSpan={12} className="tw-text-left tw-text-xs tw-tracking-wider">
      <div className="tw-ml-4 tw-flex tw-items-center tw-gap-4">
        <Checkbox
          isChecked={Object.keys(rowSelection).length > 0}
          onClick={() => table.toggleAllRowsSelected()}
          indeterminate={Object.keys(rowSelection).length < table.getRowCount()}
        />
        <Button
          onClick={() => {
            handleRemoveDeliverablesClick(Object.keys(rowSelection).map((key) => data?.[0].data[key]))
          }}
          size="cut"
          color="neutral"
          disabled={Object.keys(rowSelection).length === 0}
          className="tw-flex tw-items-center tw-gap-2 tw-p-2"
        >
          <FolderXIcon className="tw-h-4 tw-w-4" />
          <span>Remove deliverables</span>
        </Button>
      </div>
    </th>
  )
}

const TableHeaders = ({ table, rowSelection, data, handleRemoveDeliverablesClick }: TableHeadersProps) => {
  return (
    <>
      {Object.keys(rowSelection).length > 0 ? (
        <BulkActionHeaders
          table={table}
          rowSelection={rowSelection}
          data={data}
          handleRemoveDeliverablesClick={handleRemoveDeliverablesClick}
        />
      ) : (
        table.getFlatHeaders().map((header) => (
          <th
            key={header.id}
            style={{ width: `${header.getSize()}px` }}
            className="tw-black tw-font-medium tw-uppercase"
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
          </th>
        ))
      )}
    </>
  )
}

export default ProjectDeliverablesTable
