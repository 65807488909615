import { Dispatch, SetStateAction, useEffect } from 'react'
import ProjectTemplates from './project-templates'
import QueryClientProvider from 'providers/query-client-provider'

interface CreateProjectsProps {
  setTab: Dispatch<SetStateAction<string>>
}

export default function CreateProjects({ setTab }: CreateProjectsProps) {
  useEffect(() => {
    document.title = 'Create Projects'
    if (!window.location.search.includes('tab=projects')) {
      history.replaceState(null, '', '/create?tab=projects')
    }
    setTab('projects')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <QueryClientProvider>
      {/* TODO: Search has been moved to V2*/}
      {/* <CreateSearch searchValue={searchValue} handleSearchChange={handleSearchChange} /> */}
      <ProjectTemplates />
    </QueryClientProvider>
  )
}
