import QueueTypePlaceholder from 'components/core/queue-type-placeholder'
import { Ticket } from 'interfaces/ticket'

const DeliverablesTableTitleCell = ({ ticket }: { ticket: Ticket }) => {
  const onClick = () => {
    window.location.assign('/tickets/' + ticket.id)
  }

  return (
    <div className="tw-flex tw-items-center tw-gap-2">
      {ticket.thumbnail.placeholder ? (
        <div
          className="tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-rounded-lg tw-bg-peppercorn-50 hover:tw-cursor-pointer"
          onClick={onClick}
        >
          <QueueTypePlaceholder fileName={ticket.thumbnail.url} className="tw-h-8 tw-w-8" />
        </div>
      ) : (
        <img
          src={ticket.thumbnail.url}
          alt={ticket.subject}
          className="tw-h-16 tw-w-16 tw-rounded-t-lg tw-object-cover hover:tw-cursor-pointer"
          onClick={onClick}
        />
      )}
      <div className="tw-flex tw-flex-col tw-justify-center tw-gap-1">
        <div className="tw-text-cornflower-500 hover:tw-cursor-pointer" onClick={onClick}>
          {ticket.subject || `Untitled ${ticket.skillName}`}
        </div>
        <div>+ Add tag(s)</div>
      </div>
    </div>
  )
}

export default DeliverablesTableTitleCell
