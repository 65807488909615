import { ReactElement, useState } from 'react'

import { AppSettings, CurrentUser } from 'interfaces/app'

import UserProvider from 'providers/user-provider'
import PageHeader from 'components/elements/page-header'
import ExternalActionLinkItem from 'components/elements/dropdown/items/external-action-link-item'
import TicketList from './ticket-list'
import FeatureFlagsProvider from 'lib/components/feature-flags/feature-flags-provider'
import TicketRatingReminder from './ticket-rating-reminder'
import SingleQueueProvider, { useSingleQueueContext } from 'providers/single-queue-provider'
import TicketFeedbackProvider from 'providers/ticket-feedback-provider'
import { Ticket, GripHorizontal, FoldersIcon } from 'lucide-react'
import ConfirmationModalProvider from 'providers/confirmation-modal-provider'
import AllCompanyTagsProvider from 'providers/all-company-tags-provider'
import SingleQSelectedTagsProvider from 'components/pages/requests/single-q-selected-tags-provider'
import { Toaster } from 'lib/components/toast/toast'
import { LinkLikeButton } from 'components/core/button'
import ProjectList from 'components/elements/projects/project-list'
import QueryClientProvider from 'providers/query-client-provider'
import { Project } from 'interfaces/project'
import { updateParams } from 'lib/util/url'
const REQUESTS_CSV_URL = '/requests.csv'

interface SingleQueueProps {
  settings: AppSettings
  user: CurrentUser
}

function SingleQueue({ user }: SingleQueueProps): ReactElement {
  const [showAll, setShowAll] = useState(false)
  const { priorityMode, tickets, setUrlState } = useSingleQueueContext()

  const handleProjectClick = (project: Project) => {
    setUrlState((prev) => ({
      ...prev,
      projectId: project.id,
    }))

    const params = new URLSearchParams(window.location.search)
    params.set('projectId', project.id.toString())
    updateParams(params)
  }

  return (
    <TicketFeedbackProvider>
      {user.ticketFeedback && !priorityMode && <TicketRatingReminder />}
      {user.isProjectsEnabled && (
        <>
          <div className="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-pt-4">
            <div className="tw-flex tw-items-center tw-gap-2">
              <FoldersIcon className="tw-h-5 tw-w-5 tw-text-neutral-800" />
              <h3 className="tw-text-neutral-800">Projects</h3>
            </div>

            <LinkLikeButton color="primary" onClick={() => setShowAll((prev) => !prev)}>
              See {showAll ? 'less' : 'all'}
            </LinkLikeButton>
          </div>
          <ProjectList handleProjectClick={handleProjectClick} showAll={showAll} />
        </>
      )}
      <PageHeader
        title={priorityMode ? ' Re-prioritize Requests ' : ' Requests '}
        icon={priorityMode ? <GripHorizontal /> : <Ticket />}
      >
        <ExternalActionLinkItem
          label="Export as CSV"
          iconClass="far fa-regular fa-file-export"
          route={REQUESTS_CSV_URL}
        />
      </PageHeader>
      <SingleQSelectedTagsProvider tickets={tickets}>
        <TicketList />
      </SingleQSelectedTagsProvider>
    </TicketFeedbackProvider>
  )
}

export default function SingleQueuePage({ settings, user }: SingleQueueProps): ReactElement {
  return (
    <>
      <Toaster />
      <UserProvider settings={settings} user={user}>
        <FeatureFlagsProvider>
          <SingleQueueProvider userId={user.id}>
            <ConfirmationModalProvider>
              <AllCompanyTagsProvider>
                <QueryClientProvider>
                  <SingleQueue settings={settings} user={user} />
                </QueryClientProvider>
              </AllCompanyTagsProvider>
            </ConfirmationModalProvider>
          </SingleQueueProvider>
        </FeatureFlagsProvider>
      </UserProvider>
    </>
  )
}
