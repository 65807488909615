import CreateSearch from 'components/pages/create/create-search'
import SkillCategories from 'components/pages/request/skills/skill-categories'
import { useSkillsContext } from 'components/pages/request/skills/skills-provider'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import { SkillCategorySkill } from 'lib/api/skills/skills'
import { createTicketAndRedirect } from 'lib/ticket/utils'
import { useUserContext } from 'providers/user-provider'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

interface CreateDeliverablesProps {
  setTab: Dispatch<SetStateAction<string>>
}

export default function CreateDeliverables({ setTab }: CreateDeliverablesProps) {
  useEffect(() => {
    document.title = 'Create Deliverables'
    history.replaceState(null, '', '/create?tab=deliverables')
    setTab('deliverables')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <CreateDeliverablesContent />
}

function CreateDeliverablesContent() {
  const [isLoading, setIsLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const { user } = useUserContext()
  const { filterCategoriesBySkillName } = useSkillsContext()

  function handleSearchChange(value: string) {
    setSearchValue(value)
    filterCategoriesBySkillName(value)
  }

  async function handleSelect(skill: SkillCategorySkill) {
    try {
      setIsLoading(true)

      await createTicketAndRedirect(skill, user.preferredFormats)
    } catch (e) {
      setIsLoading(false)
      console.error(e)
    }
  }

  return (
    <>
      {isLoading ? (
        <LoadingScreen size="2xl" />
      ) : (
        <>
          <CreateSearch searchValue={searchValue} handleSearchChange={handleSearchChange} />
          <SkillCategories onSelect={handleSelect} size="lg" showOtherSkills fetchSkills={false} />
        </>
      )}
    </>
  )
}
