import { updateAutoAssignerTimeZone, routes } from 'lib/api/auto-assigner-time-zones/auto-assigner-time-zones'
import { AutoAssignerTimeZone } from 'lib/api/auto-assigner-time-zones/auto-assigner-time-zones'
import { snakeCaseKeys } from 'lib/object/utils'
import { ArrowLeftToLine } from 'lucide-react'
import { useState } from 'react'
import AutoAssignerTimeZoneForm from './auto-assigner-time-zone-form'
import { toast, Toaster } from 'lib/components/toast/toast'

const classNames = {
  arrowIcon: 'tw-mr-3 tw-text-peppercorn-900',
  header: 'tw-flex tw-items-center navbar-custom',
  inputsContainer: 'tw-container tw-mt-14',
  input:
    'tw-w-32 tw-py-2 tw-px-8 tw-appearance-none tw-outline-none tw-shadow-none tw-border tw-border-peppercorn-100 tw-border-solid tw-rounded',
  inputWrapper: 'tw-mb-4',
  submitButton: 'tw-w-32',
  wrapper: 'tw-flex tw-w-full tw-flex-col tw-pb-4',
}

function AutoAssignerTimeZoneEdit({ autoAssignerTimeZone }: { autoAssignerTimeZone: AutoAssignerTimeZone }) {
  const [params, setParams] = useState({
    name: autoAssignerTimeZone.name,
    startTime: autoAssignerTimeZone.startTime,
    endTime: autoAssignerTimeZone.endTime,
  })

  async function handleSubmit() {
    try {
      const { status } = await updateAutoAssignerTimeZone(autoAssignerTimeZone.id, snakeCaseKeys(params))
      if (status === 200) {
        toast.success('Auto Assigner Time Zone updated successfully')
      }
      window.open(routes.index, '_self')
    } catch (error) {
      console.error(error)
      const errorMessage = error.response?.data?.errors || 'Failed to update skill category'
      toast.error(errorMessage.toString().replace(/,/g, ', '))
    }
  }

  return (
    <div className={classNames.wrapper}>
      <div id="page-header" className={classNames.header}>
        <a href={routes.index}>
          <ArrowLeftToLine size={24} className={classNames.arrowIcon} />
        </a>
        <h3>{autoAssignerTimeZone.name}</h3>
      </div>
      <AutoAssignerTimeZoneForm params={params} setParams={setParams} handleSubmit={handleSubmit} />
    </div>
  )
}

export default function AutoAssignerTimeZoneEditPage({
  autoAssignerTimeZone,
}: {
  autoAssignerTimeZone: AutoAssignerTimeZone
}) {
  return (
    <>
      <Toaster />
      <AutoAssignerTimeZoneEdit autoAssignerTimeZone={autoAssignerTimeZone} />
    </>
  )
}
