import { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react'

import { Annotation, AnnotationRecord } from 'lib/api/annotations/annotations'
import Textarea from 'lib/components/textarea/textarea'
import IconButton from 'lib/components/buttons/icon-button'

import { useAnnotationsContext } from '../providers/annotations-provider'
import { DetailTaskType } from '../types/detail-task'
import { AnnotoriousAnnotation } from 'lib/components/annotation/annotorious-openseadragon-types'
import { toast } from 'lib/components/toast/toast'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import WYSIWYGTextarea, { footerClassNames } from 'lib/components/wysiwyg/wysiwyg-textarea'
import { cn } from 'lib/util/cn'

interface EditAnnotationProps {
  annotation: Annotation
  disableEditMode: () => void
}

export default function EditAnnotation({ annotation, disableEditMode }: EditAnnotationProps): ReactElement {
  const [value, setValue] = useState(annotation.body || '')
  const textareaRef = useRef(null)
  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const { highlightedAnnotation, saveAnnotation, selectAnnotation, setHighlightedAnnotation, updateVideoAnnotation } =
    useAnnotationsContext()

  function hasMinimumLength() {
    return value.trim().length >= 3
  }

  async function save() {
    if (value.trim() === annotation.body) {
      disableEditMode()
    } else if (hasMinimumLength()) {
      try {
        if (annotation.type === DetailTaskType.VIDEO_ANNOTATION) {
          if ('comments' in annotation.data) {
            annotation.data.comments[0].body = value
          }
          updateVideoAnnotation(annotation.id, annotation.uuid, value)
        } else {
          await saveAnnotation(annotation as AnnotationRecord<AnnotoriousAnnotation>, value)
        }
        disableEditMode()
      } catch (error) {
        console.error('EditAnnotation Error:', error)
        toast.error('There was an error updating the annotation. Refresh and try again.')
      }
    }
  }

  function onTextAreaChange(e: ChangeEvent<HTMLTextAreaElement>) {
    onChange(e.target.value)
  }

  function onChange(newValue: string) {
    setValue(newValue)
  }

  useEffect(() => {
    if (textareaRef.current) {
      if (isFeatureFlagEnabled('request_wysiwyg')) {
        textareaRef.current?.editor?.commands?.focus?.()
      } else {
        textareaRef.current.focus()
        textareaRef.current.scrollTop = textareaRef.current.scrollHeight
        textareaRef.current.setSelectionRange(textareaRef.current.value.length, textareaRef.current.value.length)
      }
    }
    selectAnnotation(annotation as AnnotationRecord<AnnotoriousAnnotation>)
    setHighlightedAnnotation(annotation as AnnotationRecord<AnnotoriousAnnotation>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFeatureFlagEnabled])

  function handleClick(e: MouseEvent) {
    e.stopPropagation()

    if (highlightedAnnotation?.uuid !== annotation?.uuid && highlightedAnnotation?.id !== annotation?.id) {
      setHighlightedAnnotation(annotation as AnnotationRecord<AnnotoriousAnnotation>)
      selectAnnotation(annotation as AnnotationRecord<AnnotoriousAnnotation>)
    }
  }

  const footer = (
    <div className={cn(footerClassNames, 'tw-min-w-10')}>
      <span>
        <IconButton color="secondary" icon={['far', 'check']} onClick={save} />
      </span>
      <span onMouseDown={(e) => e.preventDefault()}>
        <IconButton color="secondary" icon={['far', 'times']} onClick={disableEditMode} />
      </span>
    </div>
  )

  return (
    <div className="tw-relative tw-flex tw-w-full tw-items-center tw-gap-2">
      {isFeatureFlagEnabled('request_wysiwyg') ? (
        <WYSIWYGTextarea
          ref={textareaRef}
          defaultValue={annotation.body}
          onChange={onChange}
          placeholder="Edit Annotation here"
          footer={footer}
        />
      ) : (
        <>
          <Textarea
            value={value}
            onChange={onTextAreaChange}
            placeholder="Edit Annotation here"
            error={!hasMinimumLength()}
            onClick={handleClick}
            ref={textareaRef}
          />
          {footer}
        </>
      )}
    </div>
  )
}
