import { useState } from 'react'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import { BetaBadge } from 'lib/components/badge/badge'
import { DetailTaskType } from 'components/pages/request/types/detail-task'
import DirectionModal from './modals/direction-modal'

export default function DirectionsFlyoutMenu(): JSX.Element {
  const [taskType, setTaskType] = useState<DetailTaskType>(null)

  function clearTaskType() {
    setTaskType(null)
  }

  function menuItemClicked(type: DetailTaskType) {
    setTaskType(type)
  }

  return (
    <>
      <IconFlyoutMenu icon={['far', 'plus']} color="primary">
        <IconFlyoutMenu.Button onClick={() => menuItemClicked(DetailTaskType.GEN_AI_REQUEST)}>
          Help me write my design request <BetaBadge />
        </IconFlyoutMenu.Button>
        <IconFlyoutMenu.Button onClick={() => menuItemClicked(DetailTaskType.TXT2IMG)}>
          Help me create imagery <BetaBadge />
        </IconFlyoutMenu.Button>
        <IconFlyoutMenu.Button onClick={() => menuItemClicked(DetailTaskType.COPY)}>
          Add my own copy
        </IconFlyoutMenu.Button>
      </IconFlyoutMenu>
      <DirectionModal onClose={clearTaskType} type={taskType} />
    </>
  )
}
