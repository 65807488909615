import Avatar from 'components/core/avatar'
import Checkbox from 'components/core/checkbox'
import { DraggableTableRow } from 'components/core/table-row'
import DropdownMenu from 'components/elements/dropdown-menu'
import ActiveTabMenu from 'components/elements/dropdown/menus/active-tab-menu'
import ArchivedTabMenu from 'components/elements/dropdown/menus/archived-tab-menu'
import DraftTabMenu from 'components/elements/dropdown/menus/draft-tab-menu'
import { Ticket } from 'interfaces/ticket'
import { hasOnlyFalsyValues } from 'lib/object/utils'
import { Draggable, DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'

import ProjectsPopover from 'components/elements/projects/projects-popover'
import UserAvatar from 'components/elements/user-avatar'
import { EmptySearchScreen, EmptyTicketsScreen, LoadingScreenTableRow } from 'components/pages/requests/empty-screens'
import { useSingleQSelectedTagsContext } from 'components/pages/requests/single-q-selected-tags-provider'
import { CompanyTag, TaggableTag } from 'lib/api/company-tags/company-tags'
import { TicketStatusBadge } from 'lib/components/badge/badge'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import { isStatusOnDeck } from 'lib/ticket/utils'
import { ChangeTagAction } from 'providers/company-tagging-provider'
import { useConfirmationModalContext } from 'providers/confirmation-modal-provider'
import { useSingleQueueContext } from 'providers/single-queue-provider'
import RatingRow from './rating-row'
import TicketLinkElement from './ticket-link-element'
import { useCallback, useState } from 'react'
import { Project } from 'interfaces/project'
import { updateTicket } from 'lib/api/tickets/tickets'
import { toast } from 'lib/components/toast/toast'

interface TicketRowProps {
  providedDragHandleProps: DraggableProvidedDragHandleProps
  ticket: Ticket
  priorityMode?: boolean
  updateSelectedTags?: (updatedTag: CompanyTag, action: ChangeTagAction, taggableId: number) => void
  selectedTags?: TaggableTag[]
  index?: number
}

export const TicketRow = ({
  index,
  priorityMode,
  providedDragHandleProps,
  ticket,
  updateSelectedTags = () => null,
}: TicketRowProps): JSX.Element => {
  const [isProjectPopoverOpen, setIsProjectPopoverOpen] = useState(false)
  const {
    activeTab,
    dragDisable,
    bulkSelect,
    bulkStateSelected,
    bulkActionIds,
    priorityModeEnabled,
    userCanSort,
    fetchTickets,
    setBulkActionIds,
    setBulkStateSelected,
    updateTicket: updateTicketsList,
  } = useSingleQueueContext()
  const { selectedTags } = useSingleQSelectedTagsContext()

  const { menuButtonActionWithCallbacks } = useConfirmationModalContext()

  const {
    category,
    designer,
    filesDownloadUrl,
    friendlyStatusName,
    id,
    lastUpdatedForUser,
    position,
    project,
    subject,
    ticketFilesCount,
    ticketId,
    url,
    user,
  } = ticket

  const { isFeatureFlagEnabled } = useFeatureFlagsContext()

  const menuButtonAction = menuButtonActionWithCallbacks(fetchTickets, () => {
    setBulkActionIds([])
    setBulkStateSelected(null)
  })

  const BulkCheckbox = () => {
    const bulkID = ticketId || id

    return (
      <td>
        {!priorityMode && (
          <Checkbox
            id={bulkID.toString()}
            checked={bulkActionIds.some((bulkId) => bulkId.ticketId === bulkID || bulkId.requestId === bulkID)}
            label={''}
            disabled={bulkStateSelected !== null && bulkStateSelected !== friendlyStatusName}
            onChange={(event) => {
              bulkSelect(event, bulkID, friendlyStatusName, id)
            }}
          />
        )}
      </td>
    )
  }

  const updateTicketProject = useCallback(
    async (project: Project | null) => {
      try {
        const response = await updateTicket(Number(ticketId || id), { project_id: project?.id || null })
        updateTicketsList(response.ticket as Ticket)
        setIsProjectPopoverOpen(false)
        toast.success('Ticket project updated')
      } catch (error) {
        toast.error('Failed to update ticket project')
        console.error('Failed to update ticket project', error)
      }
    },
    [id, ticketId, updateTicketsList],
  )

  return (
    <>
      {activeTab === 'active' && (
        <td
          className="active:focus:tw-peppercorn-200 tw-whitespace-normal tw-break-normal tw-px-6 tw-py-4 tw-pl-4 tw-text-peppercorn-100"
          {...providedDragHandleProps}
        >
          {userCanSort && !(dragDisable || !isStatusOnDeck(friendlyStatusName)) && (
            <span>
              <i className="fas fa-grip-vertical tw-color-peppercorn-50"></i>
            </span>
          )}
        </td>
      )}

      {activeTab === 'draft' && <td className="tw-px-6 tw-py-4 tw-pl-4"></td>}
      {activeTab === 'archived' && <td className="tw-px-6 tw-py-4 tw-pl-4"></td>}
      {activeTab !== 'archived' && <BulkCheckbox />}
      {activeTab === 'active' && (
        <td className="tw-whitespace-normal tw-break-normal tw-px-6 tw-py-4 tw-pl-4 tw-text-xs tw-font-semibold">
          {position}
        </td>
      )}

      <td className="tw-whitespace-normal tw-break-normal tw-px-6 tw-py-4 tw-pl-4 tw-text-sm tw-text-gray-900">
        {priorityMode ? (
          ticket.subject
        ) : (
          <TicketLinkElement
            ticket={ticket}
            showTags={true}
            selectedTags={selectedTags?.filter((tag) => tag.taggableId === Number(ticket.ticketId || ticket.id))}
            updateSelectedTags={updateSelectedTags}
          />
        )}
      </td>

      <td className="tw-min-w-[130px] tw-whitespace-normal tw-break-normal tw-border tw-px-6 tw-py-4 tw-pl-4">
        <TicketStatusBadge status={friendlyStatusName}></TicketStatusBadge>
      </td>
      {isFeatureFlagEnabled('projects') && (
        <td>
          <ProjectsPopover
            isOpen={isProjectPopoverOpen}
            setIsOpen={setIsProjectPopoverOpen}
            project={project}
            onSelectProject={updateTicketProject}
          />
        </td>
      )}
      <td className="tw-font-gray-700 tw-whitespace-normal tw-break-normal tw-px-6 tw-py-4 tw-pl-4 tw-text-xs tw-font-medium">
        {category}
      </td>
      {activeTab === 'archived' && (
        <td className="tw-py-2 tw-pl-4 tw-pr-2">
          <RatingRow ticket={ticket} />
        </td>
      )}
      {activeTab === 'active' && (
        <td className="tw-whitespace-normal tw-break-normal tw-px-6 tw-py-4 tw-pl-4 tw-text-xs">
          <UserAvatar
            priorityModeEnabled={priorityModeEnabled}
            name={designer?.name}
            avatar={designer?.avatar}
            isDesigner={true}
          />
        </td>
      )}
      <td className="tw-whitespace-normal tw-break-normal tw-px-6 tw-py-4 tw-pl-4 tw-text-xs">
        <div className="tw-flex tw-items-center">
          <div className="tw-h-8 tw-w-8 tw-flex-shrink-0">
            <Avatar src={user?.avatar} name={user?.fullName} />
          </div>
          <div className="tw-ml-4">
            <div className="tw-text-xs tw-font-medium tw-text-gray-700">{user?.fullName}</div>
          </div>
        </div>
      </td>
      {activeTab !== 'draft' && (
        <td className="tw-whitespace-normal tw-break-normal tw-px-6 tw-py-4 tw-pl-4 tw-text-xs tw-font-semibold">
          {lastUpdatedForUser}
        </td>
      )}
      <td className="tw-w-12">
        {!priorityMode && (
          <DropdownMenu>
            {activeTab === 'active' && (
              <ActiveTabMenu
                id={id}
                index={index}
                subject={subject}
                filesDownloadUrl={filesDownloadUrl}
                status={friendlyStatusName}
                userId={user?.id}
                menuActionBtn={menuButtonAction}
                ticketFilesCount={ticketFilesCount}
              />
            )}
            {activeTab === 'draft' && (
              <DraftTabMenu
                requestId={id}
                url={url}
                subject={subject}
                userId={user.id}
                menuActionBtn={menuButtonAction}
              />
            )}
            {activeTab === 'archived' && (
              <ArchivedTabMenu
                id={id}
                filesDownloadUrl={filesDownloadUrl}
                menuActionBtn={menuButtonAction}
                status={friendlyStatusName}
                subject={subject}
                ticketFilesCount={ticketFilesCount}
                userId={user?.id}
              />
            )}
          </DropdownMenu>
        )}
      </td>
    </>
  )
}

const TableContent = (): JSX.Element => {
  const { dragDisable, tickets, fetchTickets } = useSingleQueueContext()
  const { setSelectedTags } = useSingleQSelectedTagsContext()

  function selectTag(updatedTag: CompanyTag, taggableId: number) {
    setSelectedTags((prevTags) => {
      return [...prevTags, { ...updatedTag, taggableId }] as TaggableTag[]
    })
  }

  function unselectTag(updatedTag: CompanyTag, taggableId: number) {
    setSelectedTags((prevTags) => {
      return prevTags.filter(
        (tag) => (tag.taggableId === taggableId && tag.id !== updatedTag.id) || tag.taggableId !== taggableId,
      )
    })
  }

  function updateSelectedTags(updatedTag: CompanyTag, action: ChangeTagAction, taggableId: number) {
    switch (action) {
      case ChangeTagAction.Select:
        selectTag(updatedTag, taggableId)
        break
      case ChangeTagAction.Unselect:
        unselectTag(updatedTag, taggableId)
        break
      case ChangeTagAction.Update:
      case ChangeTagAction.Delete:
        fetchTickets()
        break
      default:
        break
    }
  }

  return (
    <>
      {tickets.map((ticket, index) => {
        return (
          <Draggable
            key={ticket.id.toString()}
            draggableId={ticket.id.toString()}
            index={index}
            isDragDisabled={dragDisable || !isStatusOnDeck(ticket.friendlyStatusName)}
          >
            {(provided) => (
              <DraggableTableRow innerRef={provided.innerRef} draggableProvided={provided}>
                <TicketRow
                  providedDragHandleProps={provided.dragHandleProps}
                  ticket={ticket}
                  updateSelectedTags={updateSelectedTags}
                  index={index}
                />
              </DraggableTableRow>
            )}
          </Draggable>
        )
      })}
    </>
  )
}

const PriorityModeTableContent = (): JSX.Element => {
  const { tickets } = useSingleQueueContext()
  const displayedTickets = tickets.filter((ticket) => isStatusOnDeck(ticket.friendlyStatusName))

  return (
    <>
      {displayedTickets.map((ticket, index) => {
        return (
          <Draggable key={ticket.id.toString()} draggableId={ticket.id.toString()} index={index} isDragDisabled={false}>
            {(provided) => (
              <DraggableTableRow innerRef={provided.innerRef} draggableProvided={provided}>
                <TicketRow
                  providedDragHandleProps={provided.dragHandleProps}
                  ticket={ticket}
                  index={index}
                  priorityMode
                />
              </DraggableTableRow>
            )}
          </Draggable>
        )
      })}
    </>
  )
}

const TicketListTableContent = ({ priorityMode = false }: { priorityMode?: boolean }): JSX.Element => {
  const { activeTab, tickets, loading, filters } = useSingleQueueContext()

  if (loading) {
    return <LoadingScreenTableRow />
  }

  if (tickets.length === 0 && hasOnlyFalsyValues(filters)) {
    return <EmptyTicketsScreen activeTab={activeTab} />
  }

  if (tickets.length === 0 && !hasOnlyFalsyValues(filters)) {
    return <EmptySearchScreen />
  }

  if (priorityMode) {
    return <PriorityModeTableContent />
  }

  return <TableContent />
}

export default TicketListTableContent
