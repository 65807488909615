import { useQuery } from '@tanstack/react-query'
import { LinkLikeButton } from 'components/core/button'
import { LoadingScreen } from 'components/pages/requests/empty-screens'
import { Project } from 'interfaces/project'
import { requestQuery } from 'lib/api/fetch-api'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import { CopyIcon, FolderOpenIcon, PlusIcon, Trash2Icon } from 'lucide-react'

export interface ProjectListProps {
  handleProjectClick: (project: Project) => void
  showAll?: boolean
}

export default function ProjectList({ handleProjectClick, showAll }: ProjectListProps) {
  const { data, isLoading } = useQuery({
    queryKey: ['projects'],
    queryFn: requestQuery({
      endpoint: 'getProjects',
    }),
  })

  const projects = data?.data

  const displayedProjects = showAll ? projects : projects?.slice(0, 4)

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <div className="tw-flex tw-flex-wrap tw-gap-2">
      {displayedProjects?.map((project) => (
        <div
          key={project.id}
          className="tw-h-13 tw-flex tw-w-96 tw-items-center tw-justify-between tw-rounded-md tw-bg-white tw-p-4 tw-shadow-sm"
        >
          <div className="tw-flex tw-items-center tw-gap-2 tw-overflow-hidden">
            <FolderOpenIcon className="tw-h-5 tw-w-5 tw-text-neutral-800" />
            <div className="tw-overflow-hidden">
              <LinkLikeButton
                className="tw-m-0 tw-w-full tw-p-0 tw-text-start tw-text-sm tw-font-semibold"
                onClick={() => handleProjectClick(project)}
              >
                <div title={project.name} className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
                  {project.name}
                </div>
              </LinkLikeButton>
              <p className="tw-m-0 tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-xs tw-text-neutral-500">
                {project.description}
              </p>
            </div>
          </div>
          {project.ticketsRequiringAttentionCount > 0 && (
            <div className="tw-flex tw-aspect-square tw-min-h-6 tw-items-center tw-justify-center tw-rounded-full tw-bg-flushpink-500 tw-text-white">
              {project.ticketsRequiringAttentionCount}
            </div>
          )}
          <IconFlyoutMenu color="transparent" icon={['far', 'ellipsis-v']}>
            <IconFlyoutMenu.Button onClick={() => null}>
              <PlusIcon className="tw-h-4 tw-w-4" />
              <span className="tw-ml-2">Add deliverables</span>
            </IconFlyoutMenu.Button>
            <IconFlyoutMenu.Button onClick={() => null}>
              <CopyIcon className="tw-h-4 tw-w-4" />
              <span className="tw-ml-2">Duplicate</span>
            </IconFlyoutMenu.Button>
            <IconFlyoutMenu.Button onClick={() => null}>
              <Trash2Icon className="tw-h-4 tw-w-4 tw-text-flushpink-500" />
              <span className="tw-ml-2 tw-text-flushpink-500">Delete</span>
            </IconFlyoutMenu.Button>
          </IconFlyoutMenu>
        </div>
      ))}
    </div>
  )
}
