import { ReactElement, useEffect, useMemo, useRef } from 'react'
import { Card } from 'lib/components/card/card'
import { PenLine } from 'lucide-react'
import { useAdminMediaContext } from '../providers/admin-media-provider'
import { AdminTicketFile, AdminTicketFileState } from 'lib/api/admin/ticket-files/admin-ticket-files'
import AdminDeleteMediaButton from '../delete-media-button/admin-delete-media-button'
import { useS3PreviewImage } from 'lib/hooks/useS3PreviewImage'

interface AdminMediaItemProps {
  file: AdminTicketFile
}

interface MediaItemFooterProps {
  file: AdminTicketFile
}

interface MediaItemPlaceholderProps {
  file: AdminTicketFile
}

const classNames = {
  overlayContainer: `tw-absolute
                            tw-flex
                            tw-justify-center
                            tw-items-center
                            tw-t-0
                            tw-l-0
                            tw-w-full
                            tw-h-full
                            tw-bg-opacity-60
                            tw-bg-black
                            tw-z-1`,
  img: 'tw-max-w-full tw-block',
  imgName: 'tw-overflow-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-neutral-600',
  placeholder: {
    div: 'tw-block tw-text-center',
    img: 'tw-mx-auto tw-py-8 tw-max-w-full',
  },
  removeButtonRelativeContainer: 'tw-relative tw-h-0 tw-w-0',
  mediaItemContainer: 'tw-flex tw-flex-col tw-gap-6 tw-items-center tw-py-6',
  removeButtonAbsoluteContainer: 'tw-absolute tw--bottom-5 tw-left-36 tw-z-10',
  annotationIconRelativeContainer: 'tw-relative tw-h-0 tw-w-0',
  annotationIconAbsoluteContainer:
    'tw-absolute tw--bottom-3 tw--left-2 tw-bg-cornflower-500 tw-text-white tw-rounded-md tw-px-3 tw-flex tw-items-center tw-z-20',
}

const PLACEHOLDERS_FOLDER = 'file_placeholders'

export default function AdminMediaItem({ file }: AdminMediaItemProps): ReactElement {
  const containerRef = useRef(null)
  const { selectedFile, setExtractedPreviewIndex, setSelectedFile } = useAdminMediaContext()

  const isSelected = file.id === selectedFile?.id

  const previewUrl = useS3PreviewImage(file)

  useEffect(() => {
    if (isSelected) {
      containerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [isSelected])

  const annotationsCount = useMemo(() => {
    if (file.isExtractable) {
      return file.extractedPages.reduce((acc, pdf) => acc + pdf.annotations.length, 0)
    }
    return file.annotations?.length || 0
  }, [file.annotations?.length, file.extractedPages, file.isExtractable])

  function handleSelectFile() {
    setSelectedFile(file)
    if (file.isExtractable && file.extractedPages.length > 0) {
      setExtractedPreviewIndex(0)
    }
  }

  return (
    <Card size="custom" className="tw-w-full" onClick={handleSelectFile} isSelected={isSelected} ref={containerRef}>
      <Card.Body>
        {annotationsCount > 0 && (
          <div className={classNames.annotationIconRelativeContainer} data-testid="annotation-count-container">
            <div className={classNames.annotationIconAbsoluteContainer}>
              <PenLine className="lu-light lu-sm" />
              <span className="tw-pl-2">{annotationsCount}</span>
            </div>
          </div>
        )}
        <div className={classNames.removeButtonRelativeContainer}>
          <div className={classNames.removeButtonAbsoluteContainer}>
            <AdminDeleteMediaButton file={file} icon="times" />
          </div>
        </div>
        <div className="tw-relative tw-overflow-hidden tw-rounded-t-lg">
          {(file?.preview || file?.state === AdminTicketFileState.Rejected) && (
            <Overlay label={file?.state === AdminTicketFileState.Rejected ? "WON'T BE DELIVERED" : 'PREVIEW'} />
          )}
          {previewUrl?.startsWith(PLACEHOLDERS_FOLDER) ? (
            <MediaItemPlaceholder file={file} />
          ) : (
            <img key={file.id} src={previewUrl} alt={file.name} className={classNames.img} />
          )}
        </div>
      </Card.Body>
      <Card.Footer className="tw-h-12">
        <MediaItemFooter file={file} />
      </Card.Footer>
    </Card>
  )
}

function MediaItemFooter({ file }: MediaItemFooterProps): ReactElement {
  const fileCategory = useMemo(() => {
    if (!file.stockAsset) {
      return 'user'
    }
    return 'stock'
  }, [file.stockAsset])

  if (file.externalLink) {
    return <div className={classNames.imgName}>{file.externalLink}</div>
  }
  if (fileCategory === 'user') {
    return <div className={classNames.imgName}>{file.name}</div>
  }
  return <div className={classNames.imgName}>{file.name}</div>
}

function MediaItemPlaceholder({ file }: MediaItemPlaceholderProps): ReactElement {
  const previewUrl = useS3PreviewImage(file)
  const src = `/images/${previewUrl}`
  return (
    <div className={classNames.placeholder.div}>
      <img key={file.id} src={src} alt={file.name} className={classNames.placeholder.img} />
    </div>
  )
}

function Overlay({ label }: { label: string }): ReactElement {
  return (
    <div className={classNames.overlayContainer} data-testid="image-overlay">
      <h4 className="tw-m-0 tw-text-white">{label}</h4>
    </div>
  )
}
