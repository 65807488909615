import { Fragment, ReactElement, useEffect, useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { canI, fetchAbilities } from 'lib/api/ability/ability'

import Pagination from 'components/elements/pagination'
import Tabs from 'components/elements/tabs'
import ConfirmationModal from 'components/elements/confirmation-modal'

import Table from 'components/core/table'
import TableBody from 'components/core/table-body'

import TableContent from 'components/pages/requests/table-elements/table-content'
import TableHeaderSection from 'components/pages/requests/table-elements/table-header'
import TicketListFilters from './ticket-list-filters'
import { LoadingScreen } from './empty-screens'
import { getStateFromQueryString } from 'lib/api/tickets/tickets'
import { useSingleQueueContext } from 'providers/single-queue-provider'
import ThumbRateRequestModal from 'components/core/thumb-rate-request-modal'
import PriorityTableHeader from './table-elements/priority-table-header'
import { Transition } from '@headlessui/react'
import { Info } from 'lucide-react'
import { PriorityCancelModal, PriorityConfirmModal } from './priority-modals'
import { useConfirmationModalContext } from 'providers/confirmation-modal-provider'
import ProjectDrawer from 'components/elements/projects/project-drawer'
import { PATHS } from 'lib/constants/paths'

const transitionClasses = {
  enter: 'tw-transition-opacity tw-duration-700',
  enterFrom: 'tw-opacity-0',
  enterTo: 'tw-opacity-100',
  leave: 'tw-transition-opacity tw-duration-150',
  leaveFrom: 'tw-opacity-100',
  leaveTo: 'tw-opacity-0',
}

export default function TicketList(): ReactElement {
  const [isProjectDrawerOpen, setIsProjectDrawerOpen] = useState(false)

  const {
    activeTab,
    changeTab,
    currentPage,
    dataLoaded,
    fetchTickets,
    filters,
    handlePageChange,
    onDragEnd,
    oops,
    pageCount,
    priorityMode,
    priorityModeEnabled,
    setActiveTab,
    setCurrentPage,
    setFilters,
    setPriorityMode,
    setUrlState,
    setUserCanSort,
    tabs,
    urlState,
  } = useSingleQueueContext()

  const { isModalVisible, modalData, setModalVisible } = useConfirmationModalContext()

  useEffect(() => {
    if (dataLoaded) {
      fetchTickets().catch(oops)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filters.friendlyStatus,
    filters.userIdEq,
    filters.skillSkillCategorySubscriptionTypeEq,
    filters.brandIdEq,
    filters.companyTagsIdInAny,
  ])

  useEffect(() => {
    if (dataLoaded && filters.titleQuery === '') {
      fetchTickets().catch(oops)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.titleQuery])

  useEffect(() => {
    setActiveTab(urlState.activeTab)
    setFilters(urlState.filters)
    setCurrentPage(urlState.currentPage)

    fetchAbilities(['Ticket']).then((data) => setUserCanSort(canI('Ticket', 'sort', data)))
    fetchTickets().catch(oops)

    window.document.title = 'Requests - Design Pickle'

    window.onpopstate = () => {
      const urlState = getStateFromQueryString()
      setActiveTab(urlState.activeTab)
      setFilters(urlState.filters)
      setCurrentPage(urlState.currentPage)
      fetchTickets().catch(oops)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsProjectDrawerOpen(urlState.projectId !== null)
  }, [urlState.projectId])

  function handleSetIsProjectDrawerOpen(open: boolean) {
    if (!open) {
      window.history.pushState(null, '', `${PATHS.REQUESTS}?tab=draft`)
      setUrlState({ ...urlState, projectId: null })
      setIsProjectDrawerOpen(false)
    }

    setIsProjectDrawerOpen(open)
  }

  function TicketListTable() {
    const handleOnDragEnd = (result) => {
      if (priorityModeEnabled) {
        fetchTickets({ priority_mode: true })
          .then(({ tickets }) => {
            setPriorityMode(true)
            onDragEnd(result, tickets)
            window.scrollTo(0, 0)
          })
          .catch(() => null)
      } else {
        onDragEnd(result)
      }
    }

    return (
      <>
        {urlState?.projectId && (
          <ProjectDrawer
            isOpen={isProjectDrawerOpen}
            setOpen={handleSetIsProjectDrawerOpen}
            projectId={urlState?.projectId}
            onUpdate={() => fetchTickets().catch(oops)}
          />
        )}
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Table innerRef={provided.innerRef}>
                <TableHeaderSection droppableProvided={provided} />
                <TableBody>
                  <TableContent />
                  {provided.placeholder}
                </TableBody>
              </Table>
            )}
          </Droppable>
        </DragDropContext>
      </>
    )
  }

  function PriorityTicketListTable() {
    return (
      <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Table innerRef={provided.innerRef}>
              <PriorityTableHeader droppableProvided={provided} />
              <TableBody>
                <TableContent priorityMode />
                {provided.placeholder}
              </TableBody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  function PriorityTicketList() {
    const classNames = {
      bannerText: 'tw-flex-1 tw-pl-1 tw-mr-16 tw-text-sm tw-font-medium',
      bannerWrapper: 'tw-bg-cornflower-100 tw-h-12 tw-min-w-full tw-flex tw-items-center tw-justify-center',
      container:
        'tw-absolute tw-bg-white tw-z-10 tw-min-w-full tw-text-peppercorn-900 tw-inset-0 tw-top-16 tw-overflow-hidden',
      iconWrapper: 'tw-flex tw-items-center tw-justify-center tw-w-12 tw-h-12 tw-mr-3 tw-bg-cornflower-100 tw-rounded',
      wrapper: 'tw-bg-peppercorn-50 tw-h-14',
    }

    useEffect(() => {
      const onBeforeUnload = (ev: Event) => {
        if (priorityMode) {
          ev.preventDefault()
          ev.returnValue = priorityMode
        }
        return priorityMode
      }

      window.addEventListener('beforeunload', onBeforeUnload)

      return () => {
        window.removeEventListener('beforeunload', onBeforeUnload)
      }
    })

    return (
      <Transition as={Fragment} show={priorityMode} {...transitionClasses}>
        <div className={classNames.container}>
          <div className={classNames.wrapper}>
            <div className={classNames.bannerWrapper}>
              <div className={classNames.iconWrapper}>
                <Info />
              </div>
              <div className={classNames.bannerText}>
                You are in request re-prioritization mode. This will re-assign your requests based on priority.
              </div>
            </div>
          </div>
          <PriorityTicketListTable />
        </div>
      </Transition>
    )
  }

  if (!dataLoaded) {
    return <LoadingScreen />
  }

  return (
    <div className="tw-mb-28 tw-mt-14">
      <ConfirmationModal
        title={modalData['title']}
        message={modalData['message']}
        confirmBtnText={modalData['confirmBtnText']}
        confirmAction={modalData['confirmAction']}
        cancelAction={() => setModalVisible(false)}
        visible={isModalVisible}
      />
      <ThumbRateRequestModal />
      <PriorityCancelModal />
      <PriorityConfirmModal />
      <PriorityTicketList />
      <TicketListFilters />
      <Tabs tabs={tabs} activeTab={activeTab} onClick={changeTab} />
      <TicketListTable />
      <Pagination forcePage={currentPage - 1} pageCount={pageCount} onPageChange={handlePageChange} />
    </div>
  )
}
