import Modal from 'lib/components/modals/modal'
import Button from 'components/core/button'
import { TicketFile } from 'lib/api/ticket-files/ticket-files'
import { useRequestContext } from '../providers/request-provider'
import { cn } from 'lib/util/cn'
import { DownloadIcon, InfoIcon } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { requestQuery } from 'lib/api/fetch-api'
import { useUserContext } from 'providers/user-provider'

import { useCreateFileExport } from 'lib/hooks/useCreateFileExport'
import { displayFilesize, downloadFile, getFileExtension } from 'lib/util/file/file'
import Tooltip from 'lib/components/tooltip/tooltip'
import { PATHS } from 'lib/constants/paths'
import { fileExportSuccessToast } from 'lib/hooks/useFileExports'
import { PLACEHOLDERS_FOLDER } from 'lib/constants/file-formats'
import { useS3PreviewImage } from 'lib/hooks/useS3PreviewImage'

type FileDownloadModalProps = {
  isFileExportModalOpen: boolean
  setIsFileExportModalOpen: (open: boolean) => void
  currentTicketFile: TicketFile
  downloadUrl: string
}

interface FileFormatProps {
  formats: string[]
  selectedFormats: string[]
  handleFormatClick: (format: string) => void
}

const AdditionalFormats = ({ formats, selectedFormats, handleFormatClick }: FileFormatProps) => {
  return (
    <div className="tw-mt-4 tw-flex tw-flex-row tw-gap-2 tw-text-xs">
      {formats?.map((format) => (
        <FileFormat
          extension={format}
          onClick={() => handleFormatClick(format)}
          selected={selectedFormats.includes(format)}
          key={format}
        />
      ))}
    </div>
  )
}

const FileFormat = ({
  extension,
  selected,
  onClick,
}: {
  extension: string
  selected: boolean
  onClick?: () => void
}) => {
  return (
    <div
      className={cn(
        'transition-all duration-300 tw-flex tw-cursor-pointer tw-items-center tw-gap-2 tw-rounded-lg tw-border tw-border-solid tw-border-gray-200 tw-px-3 tw-py-2 hover:tw-border-cornflower-200 hover:tw-bg-cornflower-50',
        selected && 'tw-border-cornflower-300 tw-bg-cornflower-100',
      )}
      onClick={onClick}
    >
      <img src={`/images/file_placeholders/${extension}.png`} width={24} height={24} /> .{extension}
    </div>
  )
}

const FileDownloadModal = ({
  isFileExportModalOpen,
  setIsFileExportModalOpen,
  currentTicketFile,
  downloadUrl,
}: FileDownloadModalProps) => {
  const { ticket } = useRequestContext()
  const { user } = useUserContext()

  const { data: fileExportFormats, isLoading } = useQuery({
    queryKey: ['file-export-formats', currentTicketFile?.id],
    queryFn: requestQuery({ endpoint: 'getFileExportsFormats', query: { ticket_file_id: currentTicketFile?.id } }),
  })

  const currentFormat = getFileExtension(currentTicketFile?.name)
  const preSelectedFormats =
    fileExportFormats?.data.filter((format) => user?.preferredFormats?.includes(format) && format !== currentFormat) ||
    []
  preSelectedFormats.push(currentFormat)

  const additionalFormats = fileExportFormats?.data?.filter((format) => format !== currentFormat)

  const [selectedFormats, setSelectedFormats] = useState<string[]>(preSelectedFormats || [])

  const { createFileExport } = useCreateFileExport({
    onSuccess: () => {
      setIsFileExportModalOpen(false)
      setSelectedFormats(preSelectedFormats || [])
    },
  })

  useEffect(() => {
    setSelectedFormats(preSelectedFormats || [])
  }, [isLoading])

  const handleFormatClick = (format: string) => {
    if (selectedFormats.includes(format)) {
      setSelectedFormats((prev) => prev.filter((f) => f !== format))
    } else {
      setSelectedFormats((prev) => [...prev, format])
    }
  }

  const handleDownload = () => {
    if (selectedFormats.includes(currentFormat)) {
      downloadFile(currentTicketFile?.name, downloadUrl)
      fileExportSuccessToast({
        name: currentTicketFile?.name,
        link: downloadUrl,
      })
    }

    const formatsToExport = selectedFormats.filter((format) => format !== currentFormat)

    if (formatsToExport.length > 0) {
      createFileExport({
        ticketFileId: currentTicketFile.id,
        selectedFormats: formatsToExport,
      })
    }
  }

  const previewUrlWithS3 = useS3PreviewImage(currentTicketFile)
  const previewUrl = previewUrlWithS3.startsWith(PLACEHOLDERS_FOLDER) ? `/images/${previewUrlWithS3}` : previewUrlWithS3

  return (
    <Modal open={isFileExportModalOpen} setOpen={setIsFileExportModalOpen} className="tw-max-w-md !tw-px-4">
      <Modal.Header className="tw-font-bold">Download</Modal.Header>
      <Modal.Body className="tw-mt-4 tw-text-neutral-800" setOpen={setIsFileExportModalOpen} closeButton>
        <div className="tw-mb-1 tw-text-xs tw-text-neutral-500">Current asset</div>
        <div
          className={cn(
            'tw-mb-3 tw-flex tw-cursor-pointer tw-flex-row tw-gap-2 tw-rounded-lg tw-border tw-border-solid tw-p-2 hover:tw-border-cornflower-200 hover:tw-bg-cornflower-50',
            selectedFormats.includes(currentFormat) && 'tw-border-cornflower-300 tw-bg-cornflower-100',
          )}
          onClick={() => handleFormatClick(currentFormat)}
        >
          <img className="tw-rounded" src={previewUrl} width={80} height={80} />
          <div className="tw-flex tw-min-w-0 tw-flex-col tw-gap-2 tw-text-xs">
            <div className="tw-break-words tw-text-base tw-font-bold tw-text-neutral-800">
              {currentTicketFile?.name}
            </div>
            <div className="tw-text-neutral-500">{ticket?.subject}</div>
            <div className="tw-flex tw-flex-row tw-items-center">
              <div className="tw-flex tw-items-center tw-gap-1">
                <img
                  src={`/images/file_placeholders/${getFileExtension(currentTicketFile?.name)}.png`}
                  width={24}
                  height={24}
                />
                .{getFileExtension(currentTicketFile?.name)}
              </div>

              {currentTicketFile?.width && currentTicketFile?.height && (
                <>
                  <div className="tw-mx-2 tw-text-neutral-200">|</div>
                  <div>
                    {' '}
                    {currentTicketFile?.width} x {currentTicketFile?.height} px
                  </div>
                </>
              )}
              {currentTicketFile?.fileSize && (
                <>
                  <div className="tw-mx-2 tw-text-neutral-200">|</div>
                  <div>{displayFilesize(currentTicketFile?.fileSize)}</div>
                </>
              )}
            </div>
          </div>
        </div>
        <hr className="-tw-mx-4 tw-mb-2 tw-mt-4" />
        <div className="tw-flex tw-flex-col">
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-text-base tw-font-bold">
            Need a different format?
            <Tooltip
              content={
                <>
                  Available formats depend on the file you&apos;ve selected. Preferred formats are automatically chosen,
                  but you can update these anytime in your{' '}
                  <a
                    className="tw-text-inherit tw-underline"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={PATHS.SETTINGS}
                  >
                    profile settings
                  </a>
                  .
                </>
              }
            >
              <InfoIcon className="tw-h-4 tw-w-4" />
            </Tooltip>
          </div>
          <div className="tw-text-xs tw-text-neutral-500">Choose your format(s) to convert and download.</div>
          {additionalFormats?.length > 0 ? (
            <>
              <AdditionalFormats
                formats={additionalFormats}
                selectedFormats={selectedFormats}
                handleFormatClick={handleFormatClick}
              />
            </>
          ) : (
            <div className="tw-mt-4 tw-text-sm">
              No additional formats are available for conversion, but you can still download the file as is.
            </div>
          )}
          <div className="tw-mt-6 tw-flex tw-flex-row tw-items-center tw-justify-between">
            <div className="tw-text-sm tw-font-semibold tw-text-neutral-800">
              {selectedFormats?.length} formats selected
            </div>
            <Button disabled={selectedFormats?.length === 0} color="purple" onClick={handleDownload}>
              <DownloadIcon className="tw-mr-2 tw-h-4 tw-w-4" />
              Download
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default FileDownloadModal
