import { Designer } from 'interfaces/designer'
import { ActivityNotification } from 'interfaces/notifications'
import { ProjectTemplate } from 'interfaces/project-templates'
import { SavedSearch } from 'interfaces/saved-search'
import { SearchTicket, Ticket } from 'interfaces/ticket'
import qs from 'query-string'
import { Announcement } from './announcements/announcements'
import { TicketData } from './tickets/tickets'
import { Project } from 'interfaces/project'
import { FileExport } from 'interfaces/file-exports'

interface Endpoint {
  path: string
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'BLOB'
  baseUrl?: string
}

export const endpoints: Record<string, Endpoint> = {
  createFileExport: { path: '/file_exports', method: 'POST' },
  createProject: { path: '/projects', method: 'POST' },
  createSavedSearch: { path: '/saved_searches', method: 'POST' },
  deleteSavedSearch: { path: '/saved_searches/:id', method: 'DELETE' },
  getAllCustomProjectTemplates: { path: '/project_templates', method: 'GET' },
  getAllTickets: { path: '/tickets/all_tickets', method: 'GET' },
  getAnnouncements: { path: '/announcements', method: 'GET' },
  getCompanyDesigners: { path: '/company_designers', method: 'GET' },
  getFileExports: { path: '/file_exports', method: 'GET' },
  getFileExportsFormats: { path: '/file_exports/formats', method: 'GET' },
  getNotifications: { path: '/notifications', method: 'GET' },
  getProject: { path: '/projects/:id', method: 'GET' },
  getProjectDeliverables: { path: '/projects/:id/tickets', method: 'GET' },
  getProjects: { path: '/projects', method: 'GET' },
  getProjectTemplate: { path: '/project_templates/:id', method: 'GET' },
  getSavedSearches: { path: '/saved_searches', method: 'GET' },
  getSearch: { path: '/search', method: 'GET' },
  getTickets: { path: '/tickets', method: 'GET' },
  getUnreadCount: { path: '/notifications/unread_count', method: 'GET' },
  markNotificationRead: { path: '/notifications/mark_all_read', method: 'PATCH' },
  removeTicketsFromProject: { path: '/projects/:id/remove_tickets', method: 'PATCH' },
  updateFileExport: { path: '/file_exports/:id', method: 'PATCH' },
  updateProject: { path: '/projects/:id', method: 'PATCH' },
}

interface Meta {
  nextPage: string | null
  pageSize: number
  previousPage: string | null
  total: number
}

interface Responses {
  createProject: {
    data: Project
  }
  updateProject: {
    data: Project
  }
}

export interface Endpoints {
  createFileExport: { message: string }
  createProject: {
    name: string
    description: string
    tickets: {
      skill_ids: number[]
    }
  }
  createSavedSearch: Pick<SavedSearch, 'name' | 'parameters'>
  deleteSavedSearch: { id: string }
  getAllCustomProjectTemplates: { data: ProjectTemplate[]; meta: Meta }
  getAllTickets: { data: Ticket[]; meta: Meta }
  getAnnouncements: Announcement[]
  getCompanyDesigners: { data: Designer[] }
  getFileExports: { data: FileExport[]; meta: Meta }
  getFileExportsFormats: { data: string[] }
  getNotifications: { data: ActivityNotification[]; meta: Meta }
  getProject: { data: Project }
  getProjectDeliverables: { data: Ticket[]; meta: Meta }
  getProjects: { data: Project[] }
  getProjectTemplate: ProjectTemplate
  getSavedSearches: { data: SavedSearch[] }
  getSearch: {
    data: SearchTicket[]
    meta: {
      nextPage: string
      pageSize: number
      previousPage: string
      total: number
    }
  }
  getTickets: TicketData
  getUnreadCount: { data: { unread_count: number } }
  markNotificationRead: null
  removeTicketsFromProject: { ticket_ids: number[] }
  updateFileExport: Pick<FileExport, 'display'>
  updateProject: {
    name?: string
    brand_id?: number
    description?: string
  }
}

interface Queries {
  createFileExport: {
    ticket_file_id: number
    output_format: string
  }
  createProject: null
  createSavedSearch: null
  deleteSavedSearch: { id: string }
  getAllCustomProjectTemplates: { page: number; page_size?: number }
  getAllTickets: { page: number; page_size?: number }
  getAnnouncements: null
  getCompanyDesigners: null
  getFileExports: { ticket_id: number }
  getFileExportsFormats: { ticket_file_id: number }
  getNotifications: { page: number; page_size?: number }
  getProject: { id: number }
  getProjectDeliverables: { id: number; page: number }
  getProjects: null
  getProjectTemplate: { id: number }
  getSavedSearches: null
  getSearch: {
    page?: string
    page_size?: number
    query?: string
    sort_key?: string
    sort_order?: string
  }
  getTickets: {
    first_render?: string
    page: number
    sort_column?: string
    user_id?: number
  }
  getUnreadCount: null
  markNotificationRead: null
  removeTicketsFromProject: { id: number }
  updateFileExport: { id: number }
  updateProject: { id: number }
}

interface RequestOptions<T extends keyof Endpoints> extends Omit<RequestInit, 'body'> {
  body?: Endpoints[T]
}

interface RequestObject<T extends keyof Endpoints & keyof Queries> {
  endpoint: keyof typeof endpoints & T
  query?: Queries[T]
  requestOptions?: Omit<RequestOptions<T>, 'body'>
  body?: Endpoints[T]
}

export const request = async <T extends keyof Endpoints>({
  endpoint,
  query,
  requestOptions,
  body,
}: RequestObject<T>): Promise<T extends keyof Responses ? Responses[T] : Endpoints[T]> => {
  const { path, method, baseUrl } = endpoints[endpoint]

  const url = getUrl(`${baseUrl || ''}/api/internal${path}`, query)

  const req = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...requestOptions?.headers,
    },
    ...requestOptions,
    body: body ? JSON.stringify(body) : undefined,
  })

  // Todo: standardize errors
  if (!req.ok) {
    throw new Error(`Request failed: ${req.statusText}`)
  }

  return req.json()
}

// Makes use-query snytax better
export const requestQuery =
  <T extends keyof Endpoints>(object: RequestObject<T>) =>
  () => {
    return request(object)
  }

export const getUrl = <T extends keyof Queries>(url: string, query: Queries[T]) => {
  if (!query) {
    return url
  }

  const newQuery = {}

  // Replace placeholders in the URL with the query parameters and encode them, any other query parameters are added as query string at the end of the URL
  const encodedPath = Object.entries(query).reduce((acc, [key, value]) => {
    if (acc.includes(`:${key}`)) {
      return acc.replace(`:${key}`, encodeURIComponent(value as string))
    }

    newQuery[key] = value
    return acc
  }, url)

  const queryString = decodeURIComponent(qs.stringify(newQuery))
  const fullUrl = queryString ? `${encodedPath}?${queryString}` : encodedPath

  return fullUrl
}
