import { useState } from 'react'

import Modal from 'lib/components/modals/modal'
import WYSIWYGTextarea from 'lib/components/wysiwyg/wysiwyg-textarea'

import Button from 'components/core/button'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import { useAdminTimelineContext } from '../providers/admin-timeline-provider'
import { toast } from 'lib/components/toast/toast'

interface CustomerClarificationProps {
  open: boolean
  setOpen: (open: boolean) => void
}

export default function CustomerClarificationModal({ open, setOpen }: CustomerClarificationProps) {
  const [inputValue, setInputValue] = useState('')
  const { customerClarification } = useAdminTicketContext()
  const { createConversation } = useAdminTimelineContext()

  async function submitCustomerClarification(event) {
    event.preventDefault()

    try {
      await customerClarification()

      if (inputValue) {
        await createConversation(inputValue)
      }

      toast.success('Customer clarification sent')
      setOpen(false)
    } catch (error) {
      toast.error('Error submitting customer clarification, please try again')
      console.error('Error submitting customer clarification', error)
    }
  }

  function handleOnValueChange(value) {
    setInputValue(value)
  }

  function handleCancelClick() {
    setOpen(false)
  }

  return (
    <Modal open={open} setOpen={setOpen} size="lg" clickOutsideToClose={false}>
      <Modal.Header as="div">
        <h4 className="tw-mt-0">Request Customer Clarification</h4>
      </Modal.Header>
      <Modal.Body setOpen={setOpen} closeButton>
        <div className="tw-flex tw-flex-col tw-gap-2 tw-text-neutral-600">
          <span className="tw-font-semibold">Are you sure you want to request customer clarification?</span>
          <span>
            This will put the ticket in customer clarification state until customer responds. Ask for customer
            clarification with a message below:
          </span>
          <form className="tw-text-md tw-flex tw-flex-col tw-gap-6 tw-font-semibold">
            <WYSIWYGTextarea showCannedResponseButton defaultValue={inputValue} onChange={handleOnValueChange} />
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-end tw-gap-2">
              <Button color="neutralGray" type="button" onClick={handleCancelClick}>
                Cancel (Resume work)
              </Button>
              <Button
                color="purple"
                type="submit"
                onClick={submitCustomerClarification}
                disabled={inputValue.length < 12}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  )
}
