import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import {
  Annotation,
  AnnotationRecord,
  AnnotationStatus,
  updateVideoAnnotation as apiUpdateVideoAnnotation,
  createAnnotation,
  createVideoAnnotation,
  deleteAnnotation,
  updateAnnotation,
  VideoAnnotationData,
} from 'lib/api/annotations/annotations'
import {
  TicketFile,
  VideoJSAnnotation,
  VideoJSAnnotationLocation,
  VideoJSAnnotationObject,
} from 'lib/api/ticket-files/ticket-files'
import { DesignRequestStatus, EditMode } from 'lib/api/tickets/tickets'
import { AnnotoriousAnnotation, AnnotoriousInstance } from 'lib/components/annotation/annotorious-openseadragon-types'

import { useRequestContext } from 'components/pages/request/providers/request-provider'
import { useDirectionsContext } from 'components/pages/request/providers/timeline-directions-provider'
import { updateDetailTaskStatus } from 'lib/api/detail-tasks/detail-tasks-api'
import { useFeatureFlagsContext } from 'lib/components/feature-flags/feature-flags-provider'
import VideoPlayer from 'lib/components/video/video-player'
import { filestackCdnUrl } from 'lib/util/filestack'
import { useUserContext } from 'providers/user-provider'
import { getAnnotations } from '../annotations/utils'
import { useMediaContext } from '../media/media-provider'
import { DetailTaskType } from '../types/detail-task'

export interface ApproveRejectVideoAnnotation {
  body: string
  createdAt: string
  createdBy: string
  detailTaskId: number
  data: VideoJSAnnotation
  id: number
  status: string
  time: number
  type: string
  uuid: string
}

interface AnnotationCommand {
  execute: (msg: string) => void
}

interface AnnotationsContextProps {
  isCollaboratorView: boolean
  children: ReactNode
}

export type AnnotationsContextValue = {
  addAnnotation: (ticketFile: TicketFile, bodyText: string) => void
  annotationsInEditMode: number
  currentVersionAnnotations: () => Annotation[]
  areAnnotationsValid: () => boolean
  cancelAnnotation: () => void
  canEditAnnotation: (
    annotation?: AnnotationRecord<AnnotoriousAnnotation> | AnnotationRecord<VideoJSAnnotation>,
  ) => boolean
  createVideoPlayer: (
    target: HTMLDivElement,
    annotationTicketFile: VideoAnnotationTicketFile,
    cancelCallback: () => void,
  ) => VideoPlayer
  destroyVideoPlayer: () => void
  highlightAnnotation: (annotation: Annotation) => void
  highlightedAnnotation: Annotation
  isCollaboratorView: boolean
  removeAnnotation: (ticketFile: TicketFile, annotationId: Annotation) => Promise<unknown>
  removeVideoAnnotation: (uuid: string) => Promise<void>
  saveAnnotation: (
    annotation: AnnotationRecord<AnnotoriousAnnotation>,
    body: string,
    status?: AnnotationStatus,
  ) => Promise<unknown>
  saveAnnotationDimensions: (
    annotation: AnnotoriousAnnotation,
    ticketFileId: number,
    parentTicketFileId?: number,
  ) => Promise<unknown>
  selectAnnotation: (annotation: Annotation) => void
  setAnnotationsInEditMode: Dispatch<SetStateAction<number>>
  setAnnotorious: Dispatch<SetStateAction<unknown>>
  setHighlightedAnnotation: Dispatch<SetStateAction<Annotation>>
  setShowAnnotationRightPanel: (value: DetailTaskType) => void
  setShowImageAnnotationCreator: Dispatch<SetStateAction<boolean>>
  setShowVideoAnnotationCreator: (value: boolean) => void
  showAnnotationCreator: DetailTaskType
  subscribeToAnnotations: (event: string, callback: SubscriptionCallback) => void
  unsubscribeToAnnotations: (event: string, callback: SubscriptionCallback) => void
  updateVideoAnnotation: (id: number, uuid: string, body: string) => void
  updateVideoAnnotationStatus: (annotation: ApproveRejectVideoAnnotation, status: AnnotationStatus) => Promise<void>
  uuidMap: Record<string, number>
}

type SubscriptionCallback = (annotation: AnnotationRecord<AnnotoriousAnnotation> | VideoAnnotationData) => void

type VideoAnnotationTicketFile = Pick<TicketFile, 'id' | 'annotations' | 'handle'>

const AnnotationsContext = createContext({})

export function useAnnotationsContext(): AnnotationsContextValue {
  return useContext(AnnotationsContext) as AnnotationsContextValue
}

export default function AnnotationsProvider({ children, isCollaboratorView }: AnnotationsContextProps): ReactElement {
  const [showAnnotationCreator, setShowAnnotationCreator] = useState<DetailTaskType>(null)
  const [annotationsInEditMode, setAnnotationsInEditMode] = useState<number>(0)
  const [annotorious, setAnnotorious] = useState<AnnotoriousInstance>()
  const [saveAnnotationCommand, setSaveAnnotationCommand] = useState<AnnotationCommand>(null)
  const [selectedAnnotation, setSelectedAnnotation] = useState<Annotation>(null)
  const [highlightedAnnotation, setHighlightedAnnotation] = useState<Annotation>(null)
  const [uuidMap, setUuidMap] = useState({})

  const { setShowValidationErrors } = useRequestContext()
  const {
    editMode,
    extractedPreviewIndex,
    files,
    fetchAndSetTicket,
    getFileById,
    selectedFile,
    ticket,
    updateFileAnnotations,
    updateTicketFileVideoAnnotations,
  } = useMediaContext()
  const { token, user } = useUserContext()

  const { isFeatureFlagEnabled } = useFeatureFlagsContext()
  const { directions } = useDirectionsContext()

  const videoPlayerRef = useRef<VideoPlayer>(null)

  const mergedSubscriptions = useRef({ addImage: [], addVideo: [], highlight: [], update: [] })

  const canEditAnnotation = useCallback(
    (annotation?: AnnotationRecord<AnnotoriousAnnotation>) => {
      const isEditModeAllowed =
        editMode !== EditMode.processing &&
        editMode !== EditMode.complete &&
        selectedFile?.ticketVersion === ticket.currentVersion &&
        !selectedFile?.preview

      if (annotation) {
        const isAnnotationStatusAllowed = isCollaboratorView
          ? canCollaboratorEditAnnotation(annotation)
          : canOwnerEditAnnotation(annotation)

        return isEditModeAllowed && isAnnotationStatusAllowed
      }

      return isEditModeAllowed
    },
    [editMode, isCollaboratorView, selectedFile?.preview, selectedFile?.ticketVersion, ticket.currentVersion],
  )

  const setShowVideoAnnotationCreator = useCallback((value: boolean) => {
    setShowAnnotationCreator(value ? DetailTaskType.VIDEO_ANNOTATION : null)
  }, [])

  const getRevisionAnnotations = useCallback(() => {
    return files
      .filter((file) => file.ticketVersion === ticket.lastDeliveredVersion)
      .flatMap((file) => {
        if (file.isExtractable) {
          return file.extractedPages.flatMap((page) => page.annotations)
        }
        return file.annotations
      })
  }, [files, ticket.lastDeliveredVersion])

  const currentVersionAnnotations = useCallback(() => {
    if (ticket.status === DesignRequestStatus.draft) {
      return files.flatMap((file) => {
        if (file.isExtractable) {
          return file.extractedPages.flatMap((page) => page.annotations)
        }
        return file.annotations
      })
    } else {
      return getRevisionAnnotations()
    }
  }, [files, getRevisionAnnotations, ticket.status])

  const areAnnotationsValid = useCallback(() => {
    if (!isFeatureFlagEnabled('merging')) {
      return true
    }

    return (
      (directions?.length > 0 || currentVersionAnnotations()?.length > 0) &&
      !showAnnotationCreator &&
      annotationsInEditMode === 0
    )
  }, [
    annotationsInEditMode,
    currentVersionAnnotations,
    directions?.length,
    isFeatureFlagEnabled,
    showAnnotationCreator,
  ])

  function onSelectVideoAnnotation(videoAnnotation: VideoJSAnnotationObject) {
    if (videoAnnotation?.id) {
      const annotations = selectedFile.annotations
      const annotation = annotations.find((annotation) => annotation.data?.id === videoAnnotation.id)
      setHighlightedAnnotation(annotation)
    }
  }

  function createVideoPlayer(
    target: HTMLDivElement,
    annotationTicketFile: VideoAnnotationTicketFile,
    cancelCallback: () => void,
  ) {
    const ticketFileId = annotationTicketFile.id
    const videoUrl = `${filestackCdnUrl}/${annotationTicketFile.handle}`
    const annotationUser = { id: user.id, fullName: user.fullName }
    const player = new VideoPlayer(
      target,
      ticketFileId,
      videoUrl,
      annotationUser,
      cancelCallback,
      onSelectVideoAnnotation,
    )
    const videoAnnotations = annotationTicketFile.annotations.map(
      (annotation) => annotation.data,
    ) as VideoJSAnnotationObject[]

    function onReadyCallback() {
      if (selectedAnnotation?.type === DetailTaskType.VIDEO_ANNOTATION) {
        player.select(selectedAnnotation?.uuid)
      }
    }

    player.seed(videoAnnotations, onReadyCallback)

    player.subscribe('annotationStarted', (detail: VideoJSAnnotationLocation) => {
      setShowVideoAnnotationCreator(true)
      setSaveAnnotationCommand({
        execute: (msg: string) => {
          player.create(msg, detail)
        },
      })
    })

    player.subscribe('annotationListUpdated', async (annotationDetails: VideoJSAnnotationObject[]) => {
      const annotationDetailIds = annotationDetails.map((a) => a.id)
      const ticketFileId = player.ticketFileId
      const ticketFile = getFileById(ticketFileId)
      const updatedAnnotations = ticketFile.annotations.filter((a) =>
        annotationDetailIds.includes(String(a.data.id)),
      ) as AnnotationRecord<VideoJSAnnotation>[]
      updateTicketFileVideoAnnotations(ticketFile.id, updatedAnnotations as VideoAnnotationData[])
      setShowAnnotationCreator(null)
      fetchAndSetTicket()
    })

    player.subscribe('annotationUpdated', async (payload) => {
      const { id, body, data } = payload
      const response = await apiUpdateVideoAnnotation(id, body, data, token)
      mergedSubscriptions.current.update.forEach((cb) => cb(response.data))
    })

    player.subscribe('annotationCreated', async (annotation: VideoJSAnnotationObject) => {
      const ticketFile = getFileById(player.ticketFileId)
      const { data } = await createVideoAnnotation(ticketFile.id, annotation, token)
      const ticketFileVideoAnnotations = [...ticketFile.annotations, data]

      updateTicketFileVideoAnnotations(ticketFile.id, ticketFileVideoAnnotations as VideoAnnotationData[])
      setShowAnnotationCreator(null)
      if (setShowValidationErrors) {
        setShowValidationErrors(false)
      }
      mergedSubscriptions.current.addVideo.forEach((cb) => cb(data))

      fetchAndSetTicket()
    })

    videoPlayerRef.current = player
    return player
  }

  function addAnnotation(ticketFile: TicketFile, bodyText: string) {
    if (saveAnnotationCommand) {
      saveAnnotationCommand.execute(bodyText)
      setSaveAnnotationCommand(null)
    } else {
      return saveImageAnnotation(ticketFile, bodyText)
    }
  }

  function destroyVideoPlayer() {
    if (videoPlayerRef.current) {
      videoPlayerRef.current = null
    }
    setSaveAnnotationCommand(null)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function documentKeyDownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      selectAnnotation(null)
      setHighlightedAnnotation(null)
    }
  }

  function highlightAnnotation(annotation: AnnotationRecord<AnnotoriousAnnotation>) {
    setHighlightedAnnotation(annotation || null)

    mergedSubscriptions.current.highlight.forEach((cb) => cb(annotation || null))
  }

  async function removeAnnotation(ticketFile: TicketFile, annotation: Annotation) {
    if (annotation.type && annotation.type === DetailTaskType.VIDEO_ANNOTATION) {
      await deleteAnnotation(annotation.id, token)
      if (videoPlayerRef.current) {
        videoPlayerRef.current.remove(annotation.uuid)
      }
      return
    }

    try {
      await deleteAnnotation(annotation.id, token)

      const newAnnotations = ticketFile.annotations.filter(
        (ticketFileAnnotation) => ticketFileAnnotation.id !== annotation.id,
      )
      updateFileAnnotations(ticketFile, newAnnotations)
      fetchAndSetTicket()
      annotorious.removeAnnotation(annotation.uuid)
    } catch (e) {
      throw new Error(`There was an error deleting your annotation: ${e}`)
    }
  }

  async function removeVideoAnnotation(uuid: string) {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.remove(uuid)
    }
  }

  async function saveImageAnnotation(ticketFile: TicketFile, bodyText: string) {
    const annotation = annotorious.getSelected()
    annotation.body.push({
      created: new Date().toISOString(),
      creator: {
        id: user.id,
        name: user.fullName,
      },
      modified: new Date().toISOString(),
      purpose: 'commenting',
      type: 'TextualBody',
      value: bodyText,
    })

    // Needs to update and wait for annotorious to update the selected annotation
    await annotorious.updateSelected(annotation, true)
    const annotoriousAnnotations = annotorious.getAnnotations()
    const annotationToSave = annotoriousAnnotations[annotoriousAnnotations.length - 1]

    const response = await createAnnotation(ticketFile.id, annotationToSave, token)

    uuidMap[response.annotation.uuid] = response.annotation.id

    const newAnnotations = [response.annotation, ...ticketFile.annotations]
    updateFileAnnotations(ticketFile, newAnnotations)
    fetchAndSetTicket()
    setShowAnnotationCreator(null)
    setShowValidationErrors(false)
    annotorious.setDrawingEnabled(true)

    const mergedAnnotation = {
      ...response.annotation,
      fileName: ticketFile.name,
    }

    mergedSubscriptions.current.addImage.forEach((cb) => cb(mergedAnnotation))
  }

  function selectVideoAnnotation(uuid: string) {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.select(uuid)
    }
  }

  function setShowImageAnnotationCreator(value: boolean) {
    setShowAnnotationCreator(value ? DetailTaskType.IMAGE_ANNOTATION : null)
  }

  function setShowAnnotationRightPanel(value: DetailTaskType) {
    if (typeof value !== 'string') {
      // TODO: REMOVE THIS ERROR
      throw new Error('setShowAnnotationRightPanel does not take booleans.', value)
    }
    setShowAnnotationCreator(value)
  }

  function editReviewStatus(annotation: AnnotationRecord<AnnotoriousAnnotation>) {
    if (annotation.status === AnnotationStatus.Review) {
      return AnnotationStatus.Pending
    }
  }

  function getAnnotationRecord(
    annotoriousAnnotation: AnnotoriousAnnotation,
    ticketFile: TicketFile,
  ): AnnotationRecord<AnnotoriousAnnotation> {
    const annotationId = uuidMap[annotoriousAnnotation.id]
    const annotations = getAnnotations(ticketFile)

    return annotations.find((annotation) => annotation.id === annotationId)
  }

  async function saveAnnotation(
    annotation: AnnotationRecord<AnnotoriousAnnotation>,
    body: string,
    status?: AnnotationStatus,
  ) {
    const ticketFile = getFileById(annotation.assetId, annotation.fileParentId)
    const annotoriousAnnotation = updatedAnnotoriousAnnotation(annotation, body)

    try {
      const response = await updateAnnotation({
        assetId: annotation.assetId,
        annotationId: annotation.id,
        annotation: annotoriousAnnotation,
        annotationStatus: status || editReviewStatus(annotation),
        token: token,
      })

      if (ticketFile.id === annotation.assetId) {
        const newAnnotations = ticketFile.annotations.map((selectedFileAnnotation) => {
          if (selectedFileAnnotation.id === annotation.id) {
            return response.annotation
          }
          return selectedFileAnnotation
        }) as AnnotationRecord<AnnotoriousAnnotation>[]

        // TODO: in MediaProvider, split into addAnnotationToTicketFile, updateAnnotationInTicketFile, removeAnnotationsFromTicketFile
        updateFileAnnotations(ticketFile, newAnnotations)

        fetchAndSetTicket()

        if (annotorious) {
          annotorious.setAnnotations(newAnnotations.map((annotation) => annotation.data))
        }
      } else {
        fetchAndSetTicket()
      }

      mergedSubscriptions.current.update.forEach((cb) => cb(response.annotation))
    } catch (e) {
      throw new Error(`There was an error updating your annotation: ${e}`)
    }
  }

  function saveAnnotationDimensions(
    annotoriousAnnotation: AnnotoriousAnnotation,
    ticketFileId: number,
    parentTicketFileId?: number,
  ) {
    const ticketFile = getFileById(ticketFileId, parentTicketFileId)
    const annotationRecord = getAnnotationRecord(annotoriousAnnotation, ticketFile)
    const updatedAnnotationRecord = { ...annotationRecord, data: annotoriousAnnotation }
    return saveAnnotation(updatedAnnotationRecord, annotationRecord.body)
  }

  function updatedAnnotoriousAnnotation(
    annotation: AnnotationRecord<AnnotoriousAnnotation> | AnnotoriousAnnotation,
    body: string,
  ): AnnotoriousAnnotation {
    if (typeof annotation.id === 'number') {
      const annotationContents = (annotation as AnnotationRecord<AnnotoriousAnnotation>).data
      annotationContents.body[0].value = body

      return annotationContents
    } else {
      const annotoriousAnnotationValue = annotation as AnnotoriousAnnotation
      annotoriousAnnotationValue.body[0].value = body
      return annotoriousAnnotationValue
    }
  }

  function selectAnnotoriousAnnotation(annotationUUID: string) {
    if (annotationUUID) {
      annotorious?.selectAnnotation(annotationUUID)
    } else {
      annotorious?.cancelSelected()
      // TODO: logic around whether Annotorious has drawing enabled is subject to change in Merging
      annotorious?.setDrawingEnabled(true)
    }
  }

  function updateVideoAnnotation(id: number, uuid: string, body: string) {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.edit(id, uuid, body)
    }
  }

  async function updateVideoAnnotationStatus(annotation: ApproveRejectVideoAnnotation, status: AnnotationStatus) {
    await updateDetailTaskStatus(annotation.detailTaskId, status)

    // TODO: This does not work with Merging enabled but fixes bug with Merging disabled.
    const currentVideoFileId = selectedFile.id
    const ticketFile = getFileById(currentVideoFileId)

    const updatedAnnotations = ticketFile.annotations.map((selectedFileAnnotation) => {
      if (selectedFileAnnotation.id === annotation.id) {
        return { ...selectedFileAnnotation, status }
      }
      return selectedFileAnnotation
    }) as AnnotationRecord<VideoJSAnnotation>[]

    updateTicketFileVideoAnnotations(ticketFile.id, updatedAnnotations as VideoAnnotationData[])
    mergedSubscriptions.current.update.forEach((cb) => cb({ ...annotation, status }))
    fetchAndSetTicket()
  }

  function cancelAnnotation() {
    setShowImageAnnotationCreator(false)
    setShowVideoAnnotationCreator(false)
    setSelectedAnnotation(null)
    setHighlightedAnnotation(null)
    annotorious?.cancelSelected()
    videoPlayerRef?.current?.cancel()
  }

  function selectAnnotation(annotation: Annotation) {
    if (annotation === selectedAnnotation) return

    setSelectedAnnotation(annotation)

    if (!annotation) {
      selectAnnotoriousAnnotation(null)
      selectVideoAnnotation(null)
    } else if (annotation.type === DetailTaskType.VIDEO_ANNOTATION) {
      selectVideoAnnotation(annotation.uuid)
    } else {
      selectAnnotoriousAnnotation(annotation.uuid)
    }
  }

  function subscribeToAnnotations(event: string, callback: SubscriptionCallback) {
    mergedSubscriptions.current[event].push(callback)
  }

  function unsubscribeToAnnotations(event: string, callback: SubscriptionCallback) {
    mergedSubscriptions.current[event] = mergedSubscriptions.current[event].filter((cb) => cb !== callback)
  }

  useEffect(() => {
    if (selectedFile?.isExtractable) {
      setUuidMap(createUuidMap(selectedFile.extractedPages[extractedPreviewIndex]?.annotations || []))
    } else {
      setUuidMap(createUuidMap(selectedFile?.annotations || []))
    }
  }, [selectedFile, extractedPreviewIndex])

  useEffect(() => {
    document.addEventListener('keydown', documentKeyDownHandler)
    return () => {
      document.removeEventListener('keydown', documentKeyDownHandler)
    }
  }, [documentKeyDownHandler])

  useEffect(() => {
    function clickHandler() {
      setHighlightedAnnotation(null)
    }

    if (highlightedAnnotation) {
      document.addEventListener('click', clickHandler)

      return () => {
        document.removeEventListener('click', clickHandler)
      }
    }
  }, [highlightedAnnotation])

  const context: AnnotationsContextValue = {
    addAnnotation,
    annotationsInEditMode,
    areAnnotationsValid,
    canEditAnnotation,
    cancelAnnotation,
    createVideoPlayer,
    currentVersionAnnotations,
    destroyVideoPlayer,
    highlightAnnotation,
    highlightedAnnotation,
    isCollaboratorView,
    removeAnnotation,
    removeVideoAnnotation,
    saveAnnotation,
    saveAnnotationDimensions,
    selectAnnotation,
    setAnnotationsInEditMode,
    setAnnotorious,
    setHighlightedAnnotation,
    setShowAnnotationRightPanel,
    setShowImageAnnotationCreator,
    setShowVideoAnnotationCreator,
    showAnnotationCreator,
    subscribeToAnnotations,
    unsubscribeToAnnotations,
    updateVideoAnnotation,
    updateVideoAnnotationStatus,
    uuidMap,
  }

  return <AnnotationsContext.Provider value={context}>{children}</AnnotationsContext.Provider>
}

function createUuidMap(annotations: Annotation[]) {
  return annotations.reduce((map, annotation) => {
    return {
      ...map,
      [annotation.uuid]: annotation.id,
    }
  }, {})
}

function canCollaboratorEditAnnotation(annotation: Annotation) {
  return annotation.status === AnnotationStatus.Pending || annotation.status === AnnotationStatus.Review
}

function canOwnerEditAnnotation(annotation: Annotation) {
  return annotation.status !== AnnotationStatus.Rejected && annotation.status !== AnnotationStatus.Review
}

export enum Tools {
  Move,
  Annotate,
  Paint,
}
