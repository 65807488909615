import Modal from 'lib/components/modals/modal'
import WYSIWYGTextarea from 'lib/components/wysiwyg/wysiwyg-textarea'
import Button from 'components/core/button'
import { useEffect, useState } from 'react'
import { AdminTimelineItem } from 'lib/api/admin/timeline/admin-timeline'
import { useAdminTicketContext } from '../providers/admin-ticket-provider'
import { useAdminTimelineContext } from '../providers/admin-timeline-provider'
import { toast } from 'lib/components/toast/toast'

interface AdminHeaderQrApproveModalProps {
  setOpen: (open: boolean) => void
  open: boolean
  lastUnsentDraftMessage: AdminTimelineItem
}
export default function AdminHeaderQrApproveModal({
  open,
  setOpen,
  lastUnsentDraftMessage,
}: AdminHeaderQrApproveModalProps) {
  const [message, setMessage] = useState('')
  const { passQr, ticket } = useAdminTicketContext()
  const { fetchAndSetTimelineItems, selectedAPIFilters } = useAdminTimelineContext()

  useEffect(() => {
    if (!open) {
      setMessage('')
    } else {
      setMessage(lastUnsentDraftMessage?.body || '')
    }
  }, [lastUnsentDraftMessage?.body, open])

  async function handleSubmit() {
    try {
      await passQr(message)
      if (selectedAPIFilters.ticketVersion === 0 || selectedAPIFilters.ticketVersion === ticket.currentVersion) {
        await fetchAndSetTimelineItems()
      }
      setOpen(false)
      toast.success('Ticket approved')
    } catch (error) {
      console.error('Error approving ticket', error)
      toast.error('There was an error approving the ticket, try again')
    }
  }

  return (
    <Modal open={open} setOpen={setOpen} size="lg" clickOutsideToClose={false}>
      <Modal.Header as="div">
        <h4 className="tw-my-0 tw-text-neutral-800">Approve and Deliver Ticket</h4>
        <p className="tw-text-sm tw-font-bold tw-text-black">Are you sure you want to accept and deliver the ticket?</p>
        <p className="tw-text-sm tw-text-black">
          This will deliver the ticket to the client along with the current upload(s). the following message will be
          sent to the client:
        </p>
      </Modal.Header>
      <Modal.Body setOpen={setOpen} closeButton>
        <div className="tw-flex tw-flex-col tw-gap-2 tw-text-neutral-600">
          <form className="tw-text-md tw-flex tw-flex-col tw-gap-6 tw-font-semibold">
            <WYSIWYGTextarea
              showCannedResponseButton
              defaultValue={message}
              onChange={setMessage}
              placeholder="Start typing customer reply"
            />
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-end tw-gap-2">
              <Button
                color="neutralGray"
                type="button"
                onClick={() => {
                  setOpen(false)
                }}
              >
                Cancel (Resume quality review)
              </Button>
              <Button color="purple" onClick={handleSubmit}>
                Deliver ticket
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  )
}
