import { ReactElement, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from 'lib/components/buttons/icon-button'
import DetailTask, { DetailTaskType } from '../types/detail-task'
import DirectionsInput from './directions-input'
import { IconFlyoutMenu } from 'lib/components/dropdown-icon-menu/icon-flyout-menu'
import RequestAssistReadOnlyModal from '../ai/modals/request-assist-read-only-modal'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useDirectionsContext } from '../providers/timeline-directions-provider'
import CopyTextButton from 'lib/components/buttons/copy-text-button'

const classNames = {
  copyDescription: `
    tw-block
    tw-italic
    tw-my-2
    tw-p-2
    tw-text-peppercorn-900
    tw-whitespace-pre-wrap
  `,
  description: `
    tw-block
    tw-my-2
    tw-p-2
    tw-text-peppercorn-900
  `,
  directionContainer: `
    tw-w-full
  `,
  directionDetailsContainer: `
   tw-flex
   tw-justify-between
   tw-items-center
   tw-w-full
  `,
  directionTypeContainer: `
    tw-pl-0.5
    tw-mx-2
    tw-align-middle
    tw-flex
    tw-items-center
  `,
  directionTypeIcon: `
    tw-text-black
    tw-font-black
  `,
  editModeDirection: {
    container: `
      tw-ml-2
      tw-w-full
    `,
    x: `tw-ml-2`,
  },
  row: `
    tw-flex
    tw-items-stretch
    tw-h-auto
    tw-align-middle
    tw-divide-solid
    tw-divide-y-0
    tw-divide-x
    tw-divide-peppercorn-100
  `,
}

const icons = {
  [DetailTaskType.TASK]: ['fal', 'circle'],
  [DetailTaskType.COPY]: ['fal', 'quote-left'],
  [DetailTaskType.GEN_AI_REQUEST]: ['fal', 'sparkles'],
}

interface EditModeDirectionProps {
  direction: DetailTask
  disableEditMode: () => void
}

function EditModeDirection({ direction, disableEditMode }: EditModeDirectionProps) {
  return (
    <div className={classNames.editModeDirection.container}>
      <DirectionsInput
        direction={direction}
        focusOnInitialLoad
        onEscapeKeyPress={disableEditMode}
        onUpdated={disableEditMode}
        placeholder="Start typing general direction"
        onXButtonClick={disableEditMode}
      />
    </div>
  )
}

interface DirectionDetailsProps {
  direction: DetailTask
  enableEditMode: () => void
  onDelete: (direction: DetailTask) => void
}

function DirectionDetails({ direction, enableEditMode, onDelete }: DirectionDetailsProps) {
  const [showModal, setShowModal] = useState(false)
  const [showCopyTooltip, setShowCopyTooltip] = useState(false)
  const { canEditDirection } = useDirectionsContext()

  const ldquo = <>&ldquo;</>
  const rdquo = <>&rdquo;</>

  function handleDeleteClick() {
    onDelete(direction)
  }

  function viewOriginalPrompt() {
    setShowModal(true)
  }

  function closeModal() {
    setShowModal(false)
  }

  async function handleCopyClick() {
    await navigator.clipboard.writeText(direction.description)
    showTooltip()
  }

  function showTooltip() {
    setShowCopyTooltip(true)
    const timeout = setTimeout(() => {
      setShowCopyTooltip(false)

      clearTimeout(timeout)
    }, 3000)
  }

  function stripHtml(str) {
    const regex = /(<([^>]+)>)/gi
    return str.replace(regex, '')
  }

  return (
    <div className={classNames.directionDetailsContainer}>
      {direction.taskType !== DetailTaskType.COPY && (
        <div
          className={classNames.description}
          style={{ paddingLeft: '25px', wordBreak: 'break-word' }}
          onClick={enableEditMode}
        >
          {stripHtml(direction.description)}
        </div>
      )}
      {direction.taskType === DetailTaskType.COPY && (
        <div className={classNames.copyDescription} style={{ paddingLeft: '25px' }} onClick={enableEditMode}>
          {ldquo}
          {direction.description}
          {rdquo}
        </div>
      )}
      <div className="tw-relative tw-flex tw-items-center tw-gap-2">
        {canEditDirection(direction) ? (
          <>
            <IconButton color="secondary" icon={['fal', 'pen']} onClick={enableEditMode} />
            <IconButton color="danger" icon={['far', 'trash']} onClick={handleDeleteClick} invert />
            <IconFlyoutMenu
              color={showCopyTooltip ? 'success' : 'transparent'}
              icon={showCopyTooltip ? ['far', 'check'] : ['far', 'ellipsis-v']}
            >
              {direction.taskType === DetailTaskType.GEN_AI_REQUEST && (
                <IconFlyoutMenu.Button onClick={viewOriginalPrompt}>View Original Prompt</IconFlyoutMenu.Button>
              )}
              <IconFlyoutMenu.Button onClick={handleCopyClick}>Copy</IconFlyoutMenu.Button>
            </IconFlyoutMenu>
          </>
        ) : (
          <CopyTextButton textToCopy={direction.description} />
        )}
        {showCopyTooltip && (
          <div className="tw-absolute tw-right-0 tw-top-12 tw-z-50 tw-whitespace-nowrap tw-rounded tw-bg-neutral-500 tw-px-3 tw-py-1.5 tw-text-left tw-text-sm tw-text-white">
            Direction Copied!
          </div>
        )}
        {direction.taskType === DetailTaskType.GEN_AI_REQUEST && (
          <RequestAssistReadOnlyModal
            open={showModal}
            promptText={direction?.genAiRequest?.message}
            setOpen={closeModal}
          />
        )}
      </div>
    </div>
  )
}

interface EditableDirectionProps {
  direction: DetailTask
  onDelete: (direction: DetailTask) => void
}

export default function EditableDirection({ direction, onDelete }: EditableDirectionProps): ReactElement {
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const { canEditDirection, addDirectionToInEditMode, removeDirectionFromInEditMode } = useDirectionsContext()

  function enableEditMode() {
    if (canEditDirection(direction)) {
      setIsEditMode(true)
      addDirectionToInEditMode(direction.id)
    }
  }

  function disableEditMode() {
    setIsEditMode(false)
    removeDirectionFromInEditMode(direction)
  }

  return (
    <div className={classNames.row} style={{ minHeight: '64px' }}>
      <div className={classNames.directionTypeContainer}>
        <FontAwesomeIcon icon={getIconForDirection(direction)} className={classNames.directionTypeIcon} />
      </div>
      <div className={classNames.directionContainer}>
        {isEditMode ? (
          <EditModeDirection direction={direction} disableEditMode={disableEditMode} />
        ) : (
          <DirectionDetails direction={direction} enableEditMode={enableEditMode} onDelete={onDelete} />
        )}
      </div>
    </div>
  )
}

function getIconForDirection(direction: DetailTask): IconProp {
  return icons[direction.taskType] || icons[DetailTaskType.TASK]
}
