import { baseRequest, bindJWT, getApi } from '../api'

// This list is not a full list of all features
// Feel free to add more as needed
export type features =
  | 'collaborative_review'
  | 'gen_ai_inpainting'
  | 'merging'
  | 'production_ticket_experience'
  | 'projects'
  | 'white_label_ga'
  | 'file_export'
  | 'timezone_assignment_for_pro'
  | 'file_unzip_frontend'
  | 'show_s3_links'
  | 'request_wysiwyg'

export type featureFlags = Record<features, boolean>[]

export async function isEnabled(key: string): Promise<boolean> {
  const { data } = await baseRequest('/api/internal/features', getApi)
  return data.features[key]
}

export async function getFeatureFlags(token?: string): Promise<featureFlags> {
  const getApiWithToken = bindJWT(getApi, token)
  const { data } = await baseRequest('/api/internal/features', getApiWithToken)
  return data.features as featureFlags
}
